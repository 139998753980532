import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { loginAtom } from '../../../lib/auth';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { currentPracticeAtom } from '../../../lib/practice';

import {
  Button as Btn,
  DialogActions,
  Divider,
  Grid,
} from '@mui/material';

import {
  ColourPicker,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

import { AppointmentType } from '../../../models/AppointmentType';
import { Location } from '../../../models/Location';
import { User } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  onSave: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
  appointmentType: AppointmentType;
  setAppointmentType: (c:AppointmentType) => void;
  isLoading?: boolean;
};

export const CoachAppointmentTypeEdit: FunctionComponent<Props> = ({open, setOpen, appointmentType, setAppointmentType, onSave, isLoading}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);


  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  // const {
  //   data: locations,
  //   isLoading: locationsLoading,
  //   isError: locationsError,
  //   isSuccess: locationsSuccess,
  //   // refetch: locationsRefetch,
  // } = useQuery({
  //   queryKey: ["locations", currentPractice?.id],
  //   queryFn: () =>
  //     fetch_all<Location>(
  //       `/nl/v3/objects/fetch_all`,
  //       {
  //         object: 'location',
  //         fields: ['id', 'name', 'colour'],
  //         filter: {
  //           search: '',
  //           advanced: {
  //             practice_id: (currentPractice?.id || login?.practice_id),
  //             enabled: '1'
  //           }
  //         }
  //       },
  //       login
  //     ),
  //   enabled: !!currentPractice?.id
  // });





  // useEffect(() => {
  //   console.log(activeSlot);
  // }, [activeSlot]);

  return (<>
    <Base
      name="appointment_type_edit"
      title={`${t("general.titles.edit", "Wijzig")} ${t("coach.models.appointment_types.singular", "Afspraaktype").toLowerCase()}`}
      fullWidth
      fullScreen
      maxWidth="lg"
      dc_sx={{
        backgroundColor: '#f8f8f8'
      }}
      content={<>

        <Grid container sx={{
          marginTop: 1
        }} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("coach.appointment_types.settings.general_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="name"
              starred
              caption={t("coach.models.appointment_types.name", "Naam")}
              placeholder={t("coach.models.appointment_types.placeholders.name", "Vul hier de naam in")}
              value={appointmentType.name || ''}
              backend
              onChange={(e) => {
                setAppointmentType({
                  ...appointmentType,
                  name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              id="sort_order"
              starred
              caption={t("coach.models.appointment_types.sort_order", "Naam")}
              placeholder={t("coach.models.appointment_types.placeholders.sort_order", "Vul hier de naam in")}
              value={appointmentType.sort_order?.toString() || '100'}
              backend
              onChange={(e) => {
                setAppointmentType({
                  ...appointmentType,
                  sort_order: !!e ? parseInt(e, 10) : undefined
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ColourPicker
              label={t("coach.models.appointment_types.colour", "Kleur")}
              value={appointmentType?.extra_data?.colour || 'rgba(0, 0, 0, 0.4)'}
              onChange={(v) => {
                setAppointmentType({
                  ...appointmentType,
                  extra_data: {
                    ...(appointmentType?.extra_data || {}),
                    colour: v
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              id="duration"
              starred
              caption={t("coach.models.appointment_types.length", "Naam")}
              placeholder={t("coach.models.appointment_types.placeholders.length", "Vul hier de naam in")}
              value={appointmentType.duration || '30'}
              backend
              onChange={(e) => {
                setAppointmentType({
                  ...appointmentType,
                  duration: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Switcher
              id="note_required"
              caption={t("coach.models.appointment_types.note_required", "Opmerking van client verplicht")}
              value={!!appointmentType?.extra_data?.note_required}
              onChange={(c) => {
                setAppointmentType({
                  ...appointmentType,
                  extra_data: {
                    ...(appointmentType?.extra_data || {}),
                    note_required: !!c
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Switcher
              id="is_meeting"
              caption={t("coach.models.appointment_types.is_meeting")}
              value={!!appointmentType?.is_meeting}
              onChange={(c) => {
                setAppointmentType({
                  ...appointmentType,
                  is_meeting: !!c
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sx={{marginTop: 3}}>
            <Typography variant="h5">{t("coach.appointment_types.settings.payments_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="cost"
              caption={t("coach.models.appointment_types.cost", "Naam")}
              placeholder={t("coach.models.appointment_types.placeholders.cost", "Vul hier de naam in")}
              value={appointmentType.extra_data?.cost?.toString() || '10'}
              backend
              onChange={(e) => {
                setAppointmentType({
                  ...appointmentType,
                  extra_data: {
                    ...(appointmentType.extra_data || {}),
                    cost: !!e ? parseFloat(e) : undefined
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Switcher
              id="mollie_enabled"
              caption={t("coach.models.appointment_types.mollie_enabled", "Betaling mogelijk")}
              value={!!appointmentType?.extra_data?.mollie_settings?.enabled}
              onChange={(c) => {
                setAppointmentType({
                  ...appointmentType,
                  extra_data: {
                    ...(appointmentType?.extra_data || {}),
                    mollie_settings: {
                      ...(appointmentType?.extra_data?.mollie_settings || {}),
                      enabled: !!c
                    }
                  }
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sx={{marginTop: 3}}>
            <Typography variant="h5">{t("coach.appointment_types.settings.admin_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={4}>
            <Switcher
              id="enabled"
              caption={t("coach.models.appointment_types.enabled", "Zet dit afspraaktype aan of uit")}
              value={!!appointmentType?.enabled}
              onChange={(c) => {
                setAppointmentType({
                  ...appointmentType,
                  enabled: !!c
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Switcher
              id="is_hidden"
              caption={t("coach.models.appointment_types.is_hidden")}
              value={!!appointmentType?.is_hidden}
              onChange={(c) => {
                setAppointmentType({
                  ...appointmentType,
                  is_hidden: !!c
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">{t("coach.models.appointment_types.coaches", "Wie heeft toegang tot dit type")}</Typography>
          </Grid>
          {(coaches || []).map(coach => <Grid item xs={12} md={3}>
            <Switcher
              id={`coach${coach.id}`}
              caption={coach.full_name}
              value={(appointmentType?.coaches || []).indexOf(coach.id || 0) > -1}
              onChange={(c) => {
                if ((appointmentType?.coaches || []).indexOf(coach.id || 0) > -1) {
                  setAppointmentType({
                    ...appointmentType,
                    coaches: (appointmentType?.coaches || []).filter(fff => fff !== (coach.id || 0))
                  });
                } else {
                  setAppointmentType({
                    ...appointmentType,
                    coaches: [...(appointmentType?.coaches || []), (coach.id || 0)]
                  });
                }
              }}
            />
          </Grid>)}
        </Grid>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.close")}</Btn>
        <Btn
          onClick={onSave}
          disabled={!appointmentType.name}
          color="primary"
        >{t("client.general.buttons.submit")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}
