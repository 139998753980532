import React, { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';
import { setCookie } from 'react-use-cookie';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  DatePicker,
  FileUpload,
  Select,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Confirm } from '../../../components/v2/dialogs/Confirm';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Country } from '../../../models/Country';
import { User } from '../../../models/User';

const { DateTime } = require("luxon");

type Props = {}

export const ClientSettingsAccount: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [extendedUser, setExtendedUser] = useState<User>();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [removeOpen, setRemoveOpen] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);

  const fields = [
    'id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'get_item_thumb', 'type', 'settings', 'goal_weight',
    'practice|id/name/item_is_square/get_medium', 'full_move_groups', 'full_eat_groups', 'calendar_url', 'second_name', 'call_name', 'birth_date',
    'gender', 'spouse_name', 'national_number', 'street', 'number', 'zip', 'city', 'country_id', 'phone_mobile', 'phone_fixed'
  ];

  const {
    data: extendedUserTemp,
    isLoading: extendedUserLoading,
    isError: extendedUserError,
    isSuccess: extendedUserSuccess,
    refetch: aextendedUserRefetch,
  } = useQuery({
    queryKey: ["advices", login?.id],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: fields,
          id: login?.id
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!login,
  });

  const {
    data: countries,
    isLoading: countriesLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["countries", "select_list"],
    queryFn: () =>
      fetch_all<Country>(
        `/nl/v3/pub/fetch_all`,
        {
          object: 'country',
          fields: ['id', 'name', 'iso'],
          per_page: 20,
          page: 0,
          order: "name ASC"
        }
      ),
    enabled: true,
  });






  const mutationCheckEmail = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{exists: boolean}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/check_email`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsCheckEmailError(false);
      // setCheckEmailError(undefined);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsCheckEmailError(true);
      // setCheckEmailError(data as any);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setEmailError(!!data.exists);
    },
  });
  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      extra?: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      // if (!!refetch) refetch();
      setCookie('nutriportal_token', '');
      setCookie('nutriportal_email', '');
      navigate("/");
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      aextendedUserRefetch();
      setSavedOpen(true);
      setLogin(data);
    },
  });








  const saveUser = () => {
    if (!!extendedUser) {
      let data = new FormData();
      data.append('object', 'user');
      data.append('id', extendedUser.id?.toString() || '');
      data.append('ob[id]', extendedUser.id?.toString() || '');
      data.append('ob[first_name]', extendedUser.first_name || '');
      data.append('ob[second_name]', extendedUser.second_name || '');
      data.append('ob[last_name]', extendedUser.last_name || '');
      data.append('ob[call_name]', extendedUser.call_name || '');
      if (!!extendedUser.item) {
        data.append(`ob[item]`, extendedUser.item);
      }
      data.append('ob[birth_date]', extendedUser.birth_date || '');
      data.append('ob[gender]', extendedUser.gender?.toString() || '');
      data.append('ob[spouse_name]', extendedUser.spouse_name || '');
      data.append('ob[national_number]', extendedUser.national_number || '');

      data.append('ob[email]', extendedUser.email || '');
      data.append('ob[street]', extendedUser.street || '');
      data.append('ob[number]', extendedUser.number || '');
      data.append('ob[zip]', extendedUser.zip || '');
      data.append('ob[city]', extendedUser.city || '');
      data.append('ob[country_id]', extendedUser.country_id?.toString() || '');
      data.append('ob[phone_mobile]', extendedUser.phone_mobile || '');
      data.append('ob[phone_fixed]', extendedUser.phone_fixed || '');
      
      fields.forEach((word, index) => {
        data.append(`fields[]`, word);
      });
      // ['id', 'full_name'].forEach((word, index) => {
      //   data.append(`sub_fields[to][]`, word);
      // });
      
      mutationSave.mutate({
        formData: data
      });
    }
  }

  useEffect(() => {
    if (!!extendedUserTemp) {
      setExtendedUser(extendedUserTemp);
    }
  }, [extendedUserTemp]);
  useEffect(() => {
    let to = setTimeout(() => {
      if (!!extendedUser && !!extendedUser.email) {
        mutationCheckEmail.mutate({formData: {
          email: extendedUser.email,
          skip: login?.id
        }});
      }
    }, 2000);

    return () => {
      clearTimeout(to);
    }
  }, [!!extendedUser, extendedUser?.email]);

  return <Grid container spacing={2}>
    {!!extendedUserLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!extendedUser && <>
      <Grid item xs={12} sm={4} container spacing={2}>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Box sx={{
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              display: "flex",
              flex: 1
            }}>
              <Avatar sx={{
                height: 120,
                width: 120
              }} src={extendedUser.get_item_thumb}></Avatar>
              <Typography sx={{
                marginTop: 2,
                fontSize: "1.5em"
              }}>{extendedUser.first_name} {extendedUser.last_name}</Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Box sx={{
              textAlign: "justify"
            }}>
              <Typography>{t("coach.submenu.account_settings.password_change_description")}</Typography>
            </Box>
            <Box sx={{
              marginTop: 1,
              textAlign: "center"
            }}>
              <Button
                id="pass_reset"
                contained
                onClick={(e) => {
                  setConfirmOpen(true);
                  // if (window.confirm(t("general.confirm.password_reset", "Bent u zeker dat u dit item wil verwijderen?"))) {
                  //   // this.do_pass_reset(e);
                  // }
                }}
                label={t("coach.general.buttons.reset_password", "Wachtwoord wijzigen")}
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Box sx={{
              textAlign: "justify"
            }}>
              <Typography>{t("coach.submenu.account_settings.close_account_description")}</Typography>
            </Box>
            <Box sx={{
              marginTop: 1,
              textAlign: "center"
            }}>
              <Button
                id="close_acc"
                contained
                secondary
                onClick={(e) => {
                  setRemoveOpen(true);
                }}
                label={t("coach.general.buttons.close_account", "Account sluiten")}
              />
            </Box>
          </Paper>
        </Grid>

      </Grid>

      <Grid item xs={12} sm={8}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("client.submenu.account_settings.basic_profile")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="first_name"
                caption={t("client.models.users.first_name", "Voornaam")}
                placeholder={t("client.models.users.placeholders.first_name", "Vul hier je voornaam in")}
                value={extendedUser.first_name || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    first_name: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="second_name"
                caption={t("client.models.users.second_name", "Tussennaam")}
                placeholder={t("client.models.users.placeholders.second_name", "Vul hier je tussennaam in")}
                value={extendedUser.second_name || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    second_name: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="last_name"
                caption={t("client.models.users.last_name", "Achternaam")}
                placeholder={t("client.models.users.placeholders.last_name", "Vul hier je achternaam in")}
                value={extendedUser.last_name || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    last_name: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="call_name"
                caption={t("client.models.users.call_name", "Roepnaam")}
                placeholder={t("client.models.users.placeholders.call_name", "Vul hier je roepnaam in")}
                value={extendedUser.call_name || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    call_name: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FileUpload
                caption={t("client.models.users.item", "Afbeelding")}
                fileInputName='item'
                selected={extendedUser.item}
                onChange={(f) => {
                  setExtendedUser({
                    ...extendedUser,
                    item: f as File
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{marginTop: 1}} />
              {!!mutationSave.isPending && <CircularProgress />}
              {!mutationSave.isPending && <Button
                id="save_profile"
                disabled={!extendedUser.first_name || !extendedUser.last_name}
                contained
                onClick={(e) => {
                  saveUser();
                }}
                label={t("client.general.buttons.submit", "Opslaan")}
                sx={{
                  marginTop: 1
                }}
              />}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("client.submenu.account_settings.extended_profile")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{
                fontSize: '0.7rem'
              }}>{t("client.models.users.birth_date", "Geboortedatum")}</Typography>
              <DatePicker
                // placeholder="DD/MM/YY"
                // autoOk={true}
                disableFuture={false}
                openTo="year"
                label={t("client.models.users.birth_date", "Geboortedatum")}
                format={"dd/LL/y"}
                views={["year", "month", "day"]}
                setValue={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    birth_date: e
                  });
                }}
                value={!!extendedUser.birth_date ? DateTime.fromFormat(extendedUser.birth_date, "y-LL-dd").toFormat("dd/LL/y") : '01/01/1970' || "01/01/1970"}
                id="option_date"
                sx={{
                  box: {
                    width: 'auto',
                    display: 'inline-block'
                  },
                  field_box: {
                    "svg": {
                      display: 'none'
                    }
                  },
                  field: {
                    width: 'auto',
                    marginTop: 0,
                    "input": {
                      textAlign: 'center'
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                displayEmpty
                caption={t("client.models.users.gender", "Geslacht")}
                id="gender"
                value={extendedUser.gender || ''}
                setValue={(v) => {
                  setExtendedUser({
                    ...extendedUser,
                    gender: !!v ? parseInt(v.toString(), 10) : undefined
                  });
                }}
              >
                <MenuItem value="">------</MenuItem>
                <MenuItem value={0}>{t("general.genders.female")}</MenuItem>
                <MenuItem value={1}>{t("general.genders.male")}</MenuItem>
                <MenuItem value={2}>{t("general.genders.other")}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="spouse_name"
                caption={t("client.models.users.spouse_name", "Naam van je echtgeno(o)t(e)")}
                placeholder={t("client.models.users.placeholders.spouse_name", "Vul hier de naam van je echtgeno(o)t(e) in")}
                value={extendedUser.spouse_name || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    spouse_name: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="national_number"
                caption={t("client.models.users.national_number", "Rijksregisternummer of Burgerservicenummer")}
                placeholder={t("client.models.users.placeholders.national_number", "Vul hier je rijksregisternummer of burgerservicenummer in")}
                value={extendedUser.national_number || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    national_number: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{marginTop: 1}} />
              {!!mutationSave.isPending && <CircularProgress />}
              {!mutationSave.isPending && <Button
                id="save_profile"
                disabled={!extendedUser.first_name || !extendedUser.last_name}
                contained
                onClick={(e) => {
                  saveUser();
                }}
                label={t("client.general.buttons.submit", "Opslaan")}
                sx={{
                  marginTop: 1
                }}
              />}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("client.submenu.account_settings.contact_profile")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="email"
                error={emailError ? t("client.models.users.errors.email", "Dit adres is reeds in gebruik") : undefined}
                caption={t("client.models.users.email", "E-mail")}
                placeholder={t("client.models.users.placeholders.email", "Vul hier je email in")}
                value={extendedUser.email || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    email: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"subtitle2"}>{t("client.submenu.account_settings.contact_address")}</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextInput
                id="street"
                caption={t("client.models.users.street", "Straat")}
                placeholder={t("client.models.users.placeholders.street", "Vul hier je straat in")}
                value={extendedUser.street || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    street: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                id="number"
                caption={t("client.models.users.number", "Huisnummer")}
                placeholder={t("client.models.users.placeholders.number", "Vul hier je huisnummer in")}
                value={extendedUser.number || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    number: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                id="zip"
                caption={t("client.models.users.zip", "Postcode")}
                placeholder={t("client.models.users.placeholders.zip", "Vul hier je postcode in")}
                value={extendedUser.zip || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    zip: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextInput
                id="city"
                caption={t("client.models.users.city", "Stad of gemeente")}
                placeholder={t("client.models.users.placeholders.city", "Vul hier je stad of gemeente in")}
                value={extendedUser.city || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    city: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                displayEmpty
                caption={t("client.models.users.country_id", "Land")}
                id="country"
                value={extendedUser.country_id || ''}
                setValue={(v) => {
                  setExtendedUser({
                    ...extendedUser,
                    country_id: !!v ? parseInt(v.toString(), 10) : undefined
                  });
                }}
              >
                <MenuItem value="">{t("shared.signup.field.country")}</MenuItem>
                {(countries || []).map((country) => (<MenuItem value={country.id}>{country.name}</MenuItem>))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"subtitle2"}>{t("client.submenu.account_settings.contact_phone")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="phone_mobile"
                caption={t("client.models.users.phone_mobile", "Mobiel telefoonnummer")}
                placeholder={t("client.models.users.placeholders.phone_mobile", "Mobiel telefoonnummer")}
                type="tel"
                value={extendedUser.phone_mobile || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    phone_mobile: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="phone_fixed"
                caption={t("client.models.users.phone_fixed", "Vast telefoonnummer")}
                placeholder={t("client.models.users.placeholders.phone_fixed", "Vast telefoonnummer")}
                type="tel"
                value={extendedUser.phone_fixed || ''}
                onChange={(e) => {
                  setExtendedUser({
                    ...extendedUser,
                    phone_fixed: e
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{marginTop: 1}} />
              {!!mutationSave.isPending && <CircularProgress />}
              {!mutationSave.isPending && <Button
                id="save_profile"
                disabled={!extendedUser.first_name || !extendedUser.last_name}
                contained
                onClick={(e) => {
                  saveUser();
                }}
                label={t("client.general.buttons.submit", "Opslaan")}
                sx={{
                  marginTop: 1
                }}
              />}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>}

    <Confirm
      open={confirmOpen}
      setOpen={setConfirmOpen}
      title={t("coach.submenu.detail.password_reset")}
      content={t("general.confirm.password_reset", "Bent u zeker dat u dit item wil verwijderen?")}
      execute={() => {
        mutationCustomAction.mutate({formData: {
          object: 'user',
          class_action: 'custom_api_forgot_pass',
          id: login?.id
        }});
      }}
    />

    <Confirm
      open={removeOpen}
      setOpen={setRemoveOpen}
      title={t("client.general.buttons.close_account")}
      content={t("general.confirm.close_account", "Bent u zeker dat u dit item wil verwijderen?")}
      execute={() => {
        mutationCustomAction.mutate({formData: {
          object: 'user',
          class_action: 'custom_api_close_acount',
          id: login?.id
        }});
      }}
    />

    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Grid>;
}

