import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  Box,
  Button as Btn,
  Chip,
  DialogActions,
  Grid,
  MenuItem,
  Tooltip,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Dt,
  Editor,
  FileDrop,
  Select,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

import { Advice } from '../../../models/Advice';

const { DateTime } = require("luxon");

type Props = {
  onClick: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
  advice: Advice;
  clonedAdvice: Advice;
  setAdvice: (d:Advice) => void;
};

export const CoachEditAdvice: FunctionComponent<Props> = ({open, setOpen, advice, clonedAdvice, setAdvice, onClick}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [flickerEditor, setFlickerEditor] = useState<boolean>(false);

  // const {
  //   data: coaches,
  //   isLoading: coachesLoading,
  //   isError: coachesError,
  //   isSuccess: coachesSuccess,
  //   // refetch: coachesRefetch,
  // } = useQuery({
  //   queryKey: ["coaches", currentPractice?.id],
  //   queryFn: () =>
  //     fetch_all<User>(
  //       `/nl/v3/objects/fetch_all`,
  //       {
  //         object: 'coach',
  //         fields: ['id', 'full_name'],
  //         filter: {
  //           search: '',
  //           advanced: {
  //             practice_id: (currentPractice?.id || login?.practice_id),
  //             is_secretary: '0',
  //             enabled: '1'
  //           }
  //         }
  //       },
  //       login
  //     ),
  //   enabled: !!currentPractice?.id
  // });








  return (<>
    <Base
      name="advice_edit"
      title={t("coach.submenu.advices.edit_advice")}
      fullWidth
      maxWidth="lg"
      content={<>
        <Grid container spacing={1}>
          {!advice.id && <Grid item xs={12}>
            <Typography sx={{
              padding: 1,
              border: '1px solid transparent',
              borderColor: theme.palette.warning.main
            }}>{t(!advice.cloned ? "coach.submenu.advices.help_set_cloned" : "coach.submenu.advices.help_set_empty")} <Button
              sx={{fontSize: '0.7rem', marginLeft: 1, marginTop: 0}}
              id="switch"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setFlickerEditor(true);

                if (!!advice.cloned) {
                  setAdvice({
                    attachments: [],
                    advice_date: DateTime.now().toFormat("dd/LL/y")
                  });
                } else {
                  setAdvice({
                    ...clonedAdvice,
                    advice_date: DateTime.now().toFormat("dd/LL/y")
                  });
                }

                setTimeout(() => {setFlickerEditor(false);}, 200);
              }}
              label={t(!advice.cloned ? "coach.submenu.advices.cta_set_cloned" : "coach.submenu.advices.cta_set_empty")}
            /></Typography>
          </Grid>}

          {!!advice.id && <Grid item xs={12}>
            <Box sx={{
              border: '1px solid transparent',
              borderColor: theme.palette.primary.main,
              padding: 1,
              borderRadius: 1,
              marginTop: 1
            }}>
              {!!advice && <Typography>{t("coach.submenu.advices.time_created")}: <Dt d={advice.created_at} f="cccc DDD t" />{!!advice.creator_id && ` ${t("general.terms.by")} ${advice.creator_full_name}`}</Typography>}
              {!!advice && <Typography>{t("coach.submenu.advices.time_updated")}: <Dt d={advice.updated_at} f="cccc DDD t" />{!!advice.editor_id && ` ${t("general.terms.by")} ${advice.editor_full_name}`}</Typography>}
            </Box>
          </Grid>}

          <Grid item xs={12} md={4}>
            <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.advices.advice_date", "Datum")}</Typography>
            <DatePicker
              disableFuture={false}
              openTo="day"
              label=""
              format={"dd/LL/y"}
              views={["year", "month", "day"]}
              setValue={(e) => {
                setAdvice({
                  ...advice,
                  advice_date: e
                });
              }}
              value={advice.advice_date || ''}
              id="option_date"
              sx={{
                box: {
                  width: 'auto',
                  display: 'inline-block'
                },
                field_box: {
                  "svg": {
                    display: 'none'
                  }
                },
                field: {
                  width: 'auto',
                  marginTop: 0,
                  "input": {
                    textAlign: 'center'
                  }
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextInput
              id="weight"
              caption={t("client.models.advices.weight", "Gewicht (kg)")}
              placeholder={t("client.models.advices.placeholders.weight", "00.0")}
              value={advice?.weight || ""}
              onChange={(e) => {
                setAdvice({
                  ...advice,
                  weight: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              id="waist"
              caption={t("client.models.advices.waist", "Middelomtrek (cm)")}
              placeholder={t("client.models.advices.placeholders.waist", "000")}
              value={advice?.waist || ""}
              onChange={(e) => {
                setAdvice({
                  ...advice,
                  waist: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {!flickerEditor && <Editor
              advanced
              label={t("coach.models.advices.comment", "Commentaar")}
              value={advice?.comment || ""}
              onChange={(e) => {
                setAdvice({
                  ...advice,
                  comment: e
                });
              }}
            />}
          </Grid>
          <Grid item xs={12} md={6}>
            {!flickerEditor && <Editor
              advanced
              label={t("coach.models.advices.deals", "Afspraken")}
              value={advice?.deals || ""}
              onChange={(e) => {
                setAdvice({
                  ...advice,
                  deals: e
                });
              }}
            />}
          </Grid>
          {!!advice.feedback_deals && <>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.advices.feedback_deals", "Opmerking van de client (afspraken)")}</Typography>
              <Typography html sx={{marginTop: '-1em', paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{advice.feedback_deals}</Typography>
            </Grid>
          </>}

          <Grid item xs={12} md={6}>
            {!flickerEditor && <Editor
              advanced
              label={t("coach.models.advices.goals", "Doelstelling")}
              value={advice?.goals || ""}
              onChange={(e) => {
                setAdvice({
                  ...advice,
                  goals: e
                });
              }}
            />}
          </Grid>
          <Grid item xs={12} md={6}>
            {!flickerEditor && <Editor
              advanced
              label={t("coach.models.advices.education", "Educatie")}
              value={advice?.education || ""}
              onChange={(e) => {
                setAdvice({
                  ...advice,
                  education: e
                });
              }}
            />}
          </Grid>
          {(!!advice.feedback_goals || !!advice.feedback_education) && <>
            <Grid item xs={12} md={6}>
              {!!advice.feedback_goals && <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.advices.feedback_goals", "Opmerking van de client (goals)")}</Typography>}
              {!!advice.feedback_goals && <Typography html sx={{marginTop: '-1em', paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{advice.feedback_goals}</Typography>}
            </Grid>
            <Grid item xs={12} md={6}>
              {!!advice.feedback_education && <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.advices.feedback_education", "Opmerking van de client (educatie)")}</Typography>}
              {!!advice.feedback_education && <Typography html sx={{marginTop: '-1em', paddingLeft: 2, borderLeft: '2px solid transparent', borderColor: theme.palette.primary.main}}>{advice.feedback_education}</Typography>}
            </Grid>
          </>}

          <Grid item xs={12}>
            {/* <Dropzone
              filesLimit={5}
              maxFileSize={26214400}
              showFileNamesInPreview={true}
              showFileNames={true}
              initialFiles={this.state.active_advice.attachments ? this.state.active_advice.attachments.map((f) => f.attributes.url) : []}
              onChange={(files) => {
                this.setState({
                  files: files
                });
              }}
            /> */}
            <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.advices.attachments", "Bijlagen")}</Typography>
            <FileDrop
              onDrop={acceptedFiles => {setAdvice({
                ...advice,
                items: acceptedFiles
              });}}
              multiple={true}
              maxSize={10 * 1000 * 1000}
              items={advice.items}
              dropText="Click/Drop"
              dropHint="All.: all files, < 10mb"
            />
            {(advice.attachments || []).map(item => <Tooltip title={<Box sx={{maxWidth: 300, maxHeight: 300}}><img src={item.url} style={{maxWidth: 280, maxHeight: 280, width: 'auto', height: 'auto'}} /></Box>}><Chip label={item.file_name} variant="outlined" color="primary" sx={{marginRight: 0.5}} onDelete={() => {setAdvice({...advice, attachments: advice.attachments.filter(fff => fff.id !== item.id)});}} /></Tooltip>)}
          </Grid>

        </Grid>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.close")}</Btn>
        <Btn
          onClick={onClick}
          color="primary"
        >{t("coach.general.actions.save")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}
