import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAtom, useAtomValue } from 'jotai';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import { fetch_all, fetch_one } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../lib/v31lib";
import { currentPracticeAtom } from '../../lib/practice';
// import { loginAtom } from '../../lib/auth';

import EmailIcon from '@mui/icons-material/Email';
import FlagIcon from '@mui/icons-material/Flag';
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  MenuItem
} from '@mui/material';

import {
  Button,
  DatePicker,
  Select,
  TextInput,
  Typography
} from '../../components/v2/styled';
import { Alert } from '../../components/v2/dialogs/Alert';
import { ImagePickerAvatar } from '../../components/v2/ImagePickerAvatar';
import { PracticeLogo } from '../../components/v2/PracticeLogo';

import { Country } from '../../models/Country';
import { Practice } from '../../models/Practice';
import { User } from '../../models/User';
import { formatDate } from '@fullcalendar/core';

// const { DateTime } = require("luxon");

type Props = {
  activePractice?: Practice;
  objectLoading: boolean;
};

export const SharedRegister: FunctionComponent<Props> = ({activePractice, objectLoading}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const matchessm = useMediaQuery(theme.breakpoints.up('sm'));

  // let [searchParams, setSearchParams] = useSearchParams();
  // const [login, setLogin] = useAtom(loginAtom);
  const currentPractice = useAtomValue(currentPracticeAtom);
  // const [forgotPasswordOpen, setForgotPasswordOpen] = useState<boolean>(false);
  // const [editPasswordOpen, setEditPasswordOpen] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [item, setItem] = useState<File>();
  const [emailExists, setEmailExists] = useState<boolean>(false);
  const [gdprAccepted, setGdprAccepted] = useState<boolean>(false);
  const [practiceGdprAccepted, setPracticeGdprAccepted] = useState<boolean>(false);

  const [showPracticeGdpr, setShowPracticeGdpr] = useState<boolean>(false);

  const [errors, setErrors] = useState<{[z:string]: boolean}>({});

  const [isRegisterError, setIsRegisterError] = useState<boolean>(false);
  const [registerLoading, setRegisterLoading] = useState<boolean>(false);
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [registerError, setRegisterError] = useState<{
    success: boolean;
    data: any;
  }>();
  const [isCheckEmailError, setIsCheckEmailError] = useState<boolean>(false);
  const [checkEmailLoading, setCheckEmailLoading] = useState<boolean>(false);
  const [checkEmailSuccess, setCheckEmailSuccess] = useState<boolean>(false);
  const [checkEmailError, setCheckEmailError] = useState<{
    success: boolean;
    data: any;
  }>();

  const {
    data: countries,
    isLoading: countriesLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["countries", "select_list"],
    queryFn: () =>
      fetch_all<Country>(
        `/nl/v3/pub/fetch_all`,
        {
          object: 'country',
          fields: ['id', 'name', 'iso'],
          per_page: 20,
          page: 0,
          order: "name ASC"
        }
      ),
    enabled: true,
  });









  const mutationCheckEmail = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{exists: boolean}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/check_email`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      setIsCheckEmailError(false);
      setCheckEmailError(undefined);
      return true;
    },
    onError: (data, variables, context) => {
      setIsCheckEmailError(true);
      setCheckEmailError(data as any);
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (!!data.exists) {
        setErrors({email: true});
      } else {
        mutationRegister.mutate({formData: {
          fields: ['id', 'email'],
          user: {
            password: password,
            password_confirmation: passwordRepeat,
            first_name: firstName,
            last_name: lastName,
            email: email,
            country_id: country,
            practice_id: activePractice?.id,
            birth_date: birthDate,
            item: item,
            enabled: 1,
            is_agreed: gdprAccepted ? 1 : 0,
            is_agreed_practice: practiceGdprAccepted ? 1 : 0,
            phone_mobile: mobile
          }
        }});
      }
    },
  });

  const mutationRegister = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/users/register`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      setIsRegisterError(false);
      setRegisterError(undefined);
      setRegisterLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      setIsRegisterError(true);
      setRegisterError(data as any);
      if ((data as any).data.email || (data as any).data.stripped_email) setErrors({email: true});
      setRegisterLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setRegisterLoading(false);
      setRegisterSuccess(true);
    },
  });








  
  const doRegister = () => {
    let errors:{[z:string]: boolean} = {};
    
    if (firstName.trim() === '') errors.first_name = true;
    if (lastName.trim() === '') errors.last_name = true;
    if (email.trim() === '') errors.email = true;
    if (password.trim() === '') errors.password = true;
    if (passwordRepeat.trim() === '') errors.password_repeat = true;
    if (country.trim() === '') errors.country = true;
    if (mobile.trim() === '') errors.phone_mobile = true;
    if (password !== passwordRepeat) {
      errors.password = true;
      errors.password_repeat = true;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email)) errors.email = true;
    if (!/^\(?\+?[\d\s\(\)]+$/.test(mobile)) errors.phone_mobile = true;
    if (password.length < 8) errors.password = true;
    if (!gdprAccepted) errors.gdpr = true;
    if (!!activePractice?.get_description && !practiceGdprAccepted) errors.gdpr_practice = true;
    if (!birthDate) errors.birth_date = true;

    if (Object.values(errors).filter(v => !!v).length < 1) {
      mutationCheckEmail.mutate({formData: {
        email: email
      }});
    } else {
      setErrors(errors);
    }
  };

  return (<Grid container item xs={12} spacing={1} direction="row-reverse" sx={{
    position: 'relative'
  }}>
    {registerLoading && <Box sx={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 8,
      left: 8,
      zIndex: 999,
      backgroundColor: 'lightgrey',
      opacity: 0.6
    }}>
      
    </Box>}
    {registerLoading && <CircularProgress sx={{
      marginLeft: '-20px',
      marginRight: '-20px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 1000
    }} />}
    
    {registerSuccess && <>
      <Grid sx={{
        alignItems: "center",
        position: "relative",
        lexDirection: "column"
      }} item xs={12}>
        <Typography variant="h6" sx={{
          marginBottom: 2
        }}>{t("shared.signup.success.title").replace("{name}", name.trim().length > 0 ? ` ${name}` : "")}</Typography>
        <Typography>{t("shared.signup.success.text")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button id="success" fullwidth contained label={t("shared.buttons.sign_in")} onClick={() => {
          navigate("/login")
        }} />
      </Grid>
    </>}
    {!registerSuccess && <>
      <Grid sx={{
        alignItems: "center",
        position: "relative",
        lexDirection: "column",
        paddingLeft: `24px !important`
      }} item sm={4} xs={12}>
        <PracticeLogo
          always
          sx={{marginLeft:'auto', marginRight: 'auto', display: 'block'}}
          practice={currentPractice}
        />

        {matchessm && <ImagePickerAvatar
          image={item}
          setImage={setItem}
        />}

        <Typography variant="h6" sx={{marginBottom: 2}}>{t("client.register.default_welcome_title").replace("{name}", firstName.trim().length > 0 ? ` ${firstName}` : "")}</Typography>
        <Typography sx={{marginBottom: 2, fontSize: '0.875rem'}}>{t("client.register.default_welcome")}</Typography>

        {!matchessm && (<Box sx={{
          position: "absolute",
          backgroundColor: theme.palette.primary.main,
          [theme.breakpoints.up('sm')]: {
            height: "100%",
            top: 0,
            right: 0,
            width: '1px'
          },
          [theme.breakpoints.down('sm')]: {
            width: "100%",
            bottom: 0,
            left: 0,
            height: '1px'
          }
        }}></Box>)}
      </Grid>
      <Grid sx={{
        alignItems: "center",
        position: "relative",
        lexDirection: "column",
        paddingRight: `24px !important`
      }} item sm={8} xs={12}>
        <Box>
          <Typography variant="h1" sx={{
            textAlign: 'center',
            color: theme.palette.primary.main
          }}>
            {t("shared.signup.info.title")}
          </Typography>

          {!matchessm && <ImagePickerAvatar
            image={item}
            setImage={setItem}
          />}

          <Grid container spacing={1}>

            <Grid item xs={12} sm={6}>
              <TextInput
                error={!!errors.first_name ? t("shared.signup.error.first_name") : undefined}
                muiIcon={<PersonIcon
                  sx={{
                    color: "rgb(195,198,197)"
                  }}
                />}
                id="first_name"
                placeholder={t("shared.signup.field.first_name")}
                autoComplete="fname"
                autoFocus={false}
                required
                value={firstName}
                onChange={(v) => {
                  setFirstName(v);
                  setErrors({
                    ...errors,
                    first_name: false
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                error={!!errors.last_name ? t("shared.signup.error.last_name") : undefined}
                muiIcon={<PersonIcon
                  sx={{
                    color: "rgb(195,198,197)"
                  }}
                />}
                id="last_name"
                placeholder={t("shared.signup.field.last_name")}
                autoComplete="lname"
                required
                value={lastName}
                onChange={(v) => {
                  setLastName(v);
                  setErrors({
                    ...errors,
                    last_name: false
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextInput
                muiIcon={<EmailIcon
                  sx={{
                    color: "rgb(195,198,197)"
                  }}
                />}
                error={emailExists ? t("shared.signup.error.email_exist") : (!!errors.email ? t("shared.signup.error.email") : undefined)}
                id="email"
                placeholder={t("shared.signup.field.email")}
                autoComplete="email"
                type="email"
                required
                value={email}
                onChange={(v) => {
                  setEmail(v);
                  setErrors({
                    ...errors,
                    email: false
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <DatePicker
                label={t("shared.signup.field.birth_date")}
                error={!!errors.birth_date ? t("shared.signup.error.birth_date") : undefined}
                disableFuture
                openTo="year"
                format="DD"
                views={["year", "month", "day"]}
                id="birth_day"
                value={birthDate}
                setValue={(v) => {
                  setBirthDate(v);
                  setErrors({
                    ...errors,
                    birth_date: false
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Select
                fullWidth
                displayEmpty
                id="country"
                icon={<FlagIcon sx={{
                  color: "rgb(195,198,197)",
                  marginRight: 1,
                  zIndex: 900
                }} />}
                error={!!errors.country ? t("shared.signup.error.country") : undefined}
                value={country}
                setValue={(v) => {
                  setCountry(v.toString());
                  setErrors({
                    ...errors,
                    country: false
                  });
                }}
              >
                <MenuItem value="">{t("shared.signup.field.country")}</MenuItem>
                {(countries || []).map((country) => (<MenuItem value={country.iso}>{country.name}</MenuItem>))}
              </Select>
            </Grid>

            <Grid item xs={12}>
              <TextInput
                muiIcon={<PhoneAndroidIcon
                  sx={{
                    color: "rgb(195,198,197)"
                  }}
                />}
                error={!!errors.phone_mobile ? t("shared.signup.error.phone_mobile") : undefined}
                id="phone_mobile"
                placeholder={t("shared.signup.field.phone_mobile")}
                autoComplete="phone_mobile"
                type="tel"
                required
                value={mobile}
                onChange={(v) => {
                  setMobile(v);
                  setErrors({
                    ...errors,
                    phone_mobile: false
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput
                helperText={t("shared.signup.field.password_tip")}
                error={!!errors.password ? t("shared.signup.error.password") : undefined}
                icon={"fa-key"}
                id="password"
                placeholder={t("shared.signup.field.password")}
                type="password"
                autoComplete="new-password"
                autoFocus={false}
                value={password}
                onChange={(v) => {
                  setPassword(v);
                  setErrors({
                    ...errors,
                    phone_mobile: false
                  });
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <TextInput
                error={!!errors.password_repeat ? t("shared.signup.error.password_repeat") : undefined}
                icon={"fa-key"}
                id="password_repeat"
                placeholder={t("shared.signup.field.password_repeat")}
                type="password"
                autoComplete="repeat-password"
                autoFocus={false}
                value={passwordRepeat}
                onChange={(v) => {
                  setPasswordRepeat(v);
                  setErrors({
                    ...errors,
                    phone_mobile: false
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{
              display: "flex",
              alignItems: "center",
              "& > label": {
                padding: 1
              },
              "&.error": {
                border: "0.5px solid rgba(255, 0, 0, 0.5)"
              }
            }}>
              <Checkbox
                checked={gdprAccepted}
                onChange={(e) => {
                  setGdprAccepted(e.target.checked);
                  setErrors({
                    ...errors,
                    gdpr: false
                  });
                }}
                value="gdpr"
              />
              {!errors.gdpr && <Typography html>{t("client.register.gdpr_nutrilink")}</Typography>}
              {!!errors.gdpr && <Typography html sx={{color: theme.palette.secondary.main}}>{t("client.register.gdpr_nutrilink")}</Typography>}
            </Grid>

            {!!activePractice?.get_description && <>
              <Grid item xs={12} sx={{
                alignItems: "center",
                display: "flex",
                "& > label": {
                  padding: 1
                },
                "&.error": {
                  border: "0.5px solid rgba(255, 0, 0, 0.5)"
                }
              }}>
                <Checkbox
                  checked={practiceGdprAccepted}
                  onChange={(e) => {
                    setPracticeGdprAccepted(e.target.checked);
                    setErrors({
                      ...errors,
                      gdpr_practice: false
                    });
                  }}
                  value="gdpr"
                />
                {!errors.gdpr_practice && <Typography html>{t("client.register.gdpr_practice").replace("{practice_name}", currentPractice?.name || '')}</Typography>}
                {!!errors.gdpr_practice && <Typography html sx={{color: theme.palette.secondary.main}}>{t("client.register.gdpr_practice").replace("{practice_name}", activePractice.name)}</Typography>}
              </Grid>
              <Grid item xs={12} style={{display: "flex"}}>
                <Button
                  contained
                  id="toggle_gdpr"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPracticeGdpr(!showPracticeGdpr);
                  }}
                  label={t(!showPracticeGdpr ? "client.register.gdpr_practice_toggle_off" : "client.register.gdpr_practice_toggle_on")}
                />
              </Grid>
              {!!showPracticeGdpr && <Grid item xs={12} sx={{display: "flex"}}>
                <Typography html>{activePractice.get_description}</Typography>
              </Grid>}
            </>}

            <Grid item xs={12}>
              <Button
                // type="submit"
                id="submit_register"
                contained
                onClick={(e) => {
                  doRegister();
                }}
                label={t("shared.buttons.register")}
                fullwidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                id="back_btn"
                text
                fullwidth
                onClick={(e) => {
                  navigate("/login");
                  // @ts-ignore
                  if(!!document.getElementById("subscroller")) document.getElementById("subscroller").scrollTo(0, 0);
                }}
                label={t("shared.buttons.have_account_onboarding")}
              />
            </Grid>

            <Alert
              hidden={!isRegisterError}
              title={t("shared.signup.error.title")}
              content={t("shared.signup.error.text")}
            />

          </Grid>

          {/* {this.props.renderForm(this.props.practice || this.props.subdomain_practice)} */}
        </Box>
        {!!matchessm && (<Box sx={{
          position: "absolute",
          backgroundColor: theme.palette.primary.main,
          [theme.breakpoints.up('sm')]: {
            height: "100%",
            top: 0,
            right: 0,
            width: '1px'
          },
          [theme.breakpoints.down('sm')]: {
            width: "100%",
            bottom: 0,
            left: 0,
            height: '1px'
          }
        }}></Box>)}
      </Grid>
    </>}
  </Grid>);
}
