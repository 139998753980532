import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import EditIcon from '@mui/icons-material/Edit';

import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
} from '@mui/material';

import {
  Typography
} from '../../../components/v2/styled';
import { ClientConsentEdit } from '../../../components/v2/dialogs/ClientConsentEdit';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Consent } from '../../../models/Consent';

// const { DateTime } = require("luxon");

type Props = {}

export const ClientSettingsConsents: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [editConsentOpen, setEditConsentOpen] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [activeConsent, setActiveConsent] = useState<Consent>();

  const {
    data: consents,
    isLoading: consentsLoading,
    isError: consentsError,
    isSuccess: consentsSuccess,
    refetch: consentsRefetch,
  } = useQuery({
    queryKey: ["advices", login?.id],
    queryFn: () =>
      fetch_all<Consent>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'consent',
          fields: ['id', 'practice|id/name/get_medium/item_is_square/get_description', 'enabled', 'is_basic', 'is_intake', 'created_at', 'updated_at', 'is_measure_weight', 'is_diary_eat', 'is_diary_move'],
          filter: {
            search: '',
            advanced: {
              user_id: login?.id
            }
          }
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!login,
  });






  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Consent>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      consentsRefetch();
      setActiveConsent(undefined);
      setEditConsentOpen(false);
      setSavedOpen(true);
      // setLogin(data);
    },
  });








  return <Grid container spacing={2}>
    {!!consentsLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!consentsSuccess && consents.length < 1 && <Box sx={{textAlign: 'center'}}>
      <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("general.no_results_found")}</Typography> 
    </Box>}
    {!!consentsSuccess && consents.length > 0 && <>
      <Grid item xs={12} container spacing={2}>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Typography variant={"subtitle2"}>{t("client.settings.consents.overview")}</Typography>
            <Divider />
            <List>
              {consents.map(consent => {
                let rights: string = '';

                if (consent.enabled) {
                  let list = [];
                  if (consent.is_basic) list.push(t("client.consents.is_basic"));
                  if (consent.is_intake) list.push(t("client.consents.is_intake"));
                  rights = `${t("client.consents.enabled")}${list.join(", ")}`;
                } else {
                  if (consent.created_at === consent.updated_at) {
                    rights = t("client.consents.pending_validation");
                  } else {
                    rights = t("client.consents.disabled");
                  }
                }

                return (<ListItem>
                  <ListItemAvatar>
                    <Avatar src={consent.practice_get_medium} sx={{
                      marginRight: 3,
                      ...(consent.practice_item_is_square ? {
                        width: 67,
                      } : {
                        width: 67,
                        '& > img': {
                          objectFit: 'contain'
                        }
                      })
                    }}></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={consent.practice_name} secondary={rights}/>
                  <ListItemSecondaryAction>

                    <Tooltip title={t("client.general.actions.edit", "Wijzig")}>
                      <IconButton aria-label="edit" onClick={(e) => {
                        setActiveConsent(consent);
                        setEditConsentOpen(true);
                        // this.props.history.push({pathname: `/consent/${consent.id}`, state: {p: consent.p}});
                      }}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                  </ListItemSecondaryAction>
                </ListItem>);
              })}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </>}

    {!!activeConsent && <ClientConsentEdit
      onSave={() => {
        mutationSave.mutate({
          formData: {
            object: "consent",
            id: activeConsent?.id,
            ob: {
              ...activeConsent,
              is_basic: activeConsent.enabled
            },
            handler_id: login?.id
          }
        });
      }}
      open={editConsentOpen}
      setOpen={(b) => {
        setActiveConsent(undefined);
        setEditConsentOpen(b);
      }}
      consent={activeConsent}
      setConsent={setActiveConsent}
      isLoading={mutationSave.isPending}
    />}

    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Grid>;
}

