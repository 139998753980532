import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAtom, useAtomValue } from 'jotai';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import { fetch_all, fetch_one } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../lib/v31lib";
import { currentPracticeAtom } from '../../lib/practice';
// import { loginAtom } from '../../lib/auth';

import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  ImageListItem,
  Paper,
  Toolbar
} from '@mui/material';

import {
  ImageList,
  Typography
} from '../../components/v2/styled';
import { CardWorkshop } from '../../components/v2/cards/Workshop';
import { LanguagePicker } from '../../components/v2/LanguagePicker';
import { PracticeLogo } from '../../components/v2/PracticeLogo';
import { SnackAppointmentMade } from '../../components/v2/snacks/AppointmentMade';
import { VersionButton } from '../../components/v2/VersionButton';

import { SharedLogin } from './Login';
import { SharedRegister } from './Register';

import { Practice } from '../../models/Practice';
// import { User } from '../../models/User';
import { Workshop } from '../../models/Workshop';

const { DateTime } = require("luxon");

type Props = {
  isRegister?: boolean;
};

export const SharedOnboarding: FunctionComponent<Props> = ({isRegister}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const { state } = useLocation();

  const [showAppointmentSuccess, setShowAppointmentSuccess] = useState<boolean>(!!state && !!state.show_appointment_success);

  const matchessm = useMediaQuery(theme.breakpoints.up('sm'));

  // const [login, setLogin] = useAtom(loginAtom);
  const currentPractice = useAtomValue(currentPracticeAtom);
  const [clicks, setClicks] = useState<number>(0);
  const [showLangPicker, setShowLangPicker] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(!isRegister);

  const {
    data: activePractice,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["onboarding", "practice", currentPractice?.id],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_practice`,
        {
          fields: ['id', 'get_my_practice', 'settings', 'get_description'],
          id: currentPractice?.id
        },
        undefined
      ),
    enabled: !!currentPractice,
  });

  const {
    data: workshops,
    isLoading: workshopsLoading,
    isError: workshopsError,
    isSuccess: workshopsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["onboarding", "workshops", currentPractice?.id],
    queryFn: () =>
      fetch_all<Workshop>(
        `/nl/v3/pub/fetch_all`,
        {
          object: 'workshop',
          fields: ['id', 'subject', 'description', 'get_medium', 'start_time'],
          per_page: 20,
          page: 0,
          order: "start_time ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: currentPractice?.id
            }
          },
        }
      ),
    enabled: !!currentPractice,
  });

  useEffect(() => {
    setIsLogin(location.pathname === '/login');
  }, [location.pathname]);

  let term = t("client.dashboard.workshops.title", "Workshops").toLowerCase();
  if (!!activePractice?.settings?.workshops_plural) {
    term = activePractice.settings.workshops_plural.toLowerCase();
  }

  return (<Box sx={{
    flexGrow: 1,
    height: "100vh",
    overflowY: "scroll",
    overflowX: "none",
    width: "100%",
    "-webkit-overflow-scrolling": "touch",
    // padding: theme.spacing(3)
    "&>*": {
      minWidth: 300,
      overflowX: "hidden"
    }
  }} id="scroller">
    <Box sx={{height: '100vh', overflowX: 'initial'}}>
      <Box sx={{
        backgroundPosition: "center center",
        backgroundSize: "cover",
        top: 0,
        width: "100%",
        height: "100%",
        backgroundImage: theme.palette.fancy_bg_img,
        position: "absolute"
      }}></Box>
      <Box sx={{
        backgroundColor: "rgba(255,255,255,0.55)",
        // backgroundColor: "rgba(92,198,50,0.25)",
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%"
      }}></Box>
      <Box sx={{
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto",
        [theme.breakpoints.down('sm')]: {
          alignItems: "flex-start"
        }
      }}>

        {!!matchessm && <Box sx={{
          backgroundColor: 'lightgrey',
          width: 20,
          height: 20,
          position: 'absolute',
          bottom: 0,
          left: 0
        }} onClick={() => {
          setClicks(clicks + 1);
        }}></Box>}

        {!!matchessm && (showLangPicker || clicks > 9) && <Box sx={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          padding: 1.25
        }}>
          <LanguagePicker />
        </Box>}

        <Paper sx={{
          padding: 3,
          minHeight: 150,
          height: '100%',
          marginTop: 8,
          width: 900,
          margin: 1,
          backgroundColor: "rgba(255,255,255,1.00)",
          [theme.breakpoints.up('sm')]: {
            maxHeight: "95%",
            maxWidth: "95%",
            overflowY: "auto",
            height: 'auto'
          },
          [theme.breakpoints.down('sm')]: {
            width: "100%",
            position: "fixed",
            height: "100%",
            margin: 0,
            backgroundColor: "rgba(255,255,255,0.85)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            paddingTop: '56px',
            overflowY: "auto",
            overflowX: "hidden",
            "-webkit-overflow-scrolling": "touch"
          }
        }} id="subscroller">
          {!matchessm && (<AppBar
            position="fixed"
            sx={{color: 'white'}}
          >
            <Toolbar sx={{height: 56}}>
              <Box sx={{
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                flexGrow: 1,
                marginLeft: 8,
                height: 56,
                "& > img": {
                  margin: 0,
                  maxHeight: "75%"
                }
              }}>
                <PracticeLogo
                  practice={currentPractice}
                />
              </Box>
              {isLogin && <IconButton onClick={(event) => {
                const main_element = document.getElementById("subscroller");
                const element = document.getElementById("form");
                if (element && main_element) {
                  main_element.scrollTo({
                    top: element.offsetTop - 10,
                    behavior: "smooth"
                  });
                }
              }}>
                <Avatar sx={{
                  width: 37,
                  height: 37
                }} src={"/images/default-avatar.png"}/>
              </IconButton>}
              {!isLogin && <IconButton onClick={(event) => {
                navigate("/login")
              }}>
                <Avatar sx={{
                  width: 37,
                  height: 37
                }} src={"/images/default-avatar.png"}/>
              </IconButton>}
            </Toolbar>
          </AppBar>)}

          <Grid container spacing={1}>
            {isLogin && <SharedLogin
              showAppointmentSuccess={showAppointmentSuccess}
              objectLoading={objectLoading}
              activePractice={activePractice}
            />}

            {isLogin && (workshops || []).length > 0 && <>
              <Box sx={{
                marginTop: 3,
                width: '100%',
                position: "relative",
                [theme && theme.breakpoints.down('sm')]: {
                  marginLeft: -2,
                  marginRight: -2
                }
              }}>
                <Divider sx={{
                  marginBottom: 2,
                  backgroundColor: theme.palette.primary.main
                }} />
                <Typography variant="subtitle2">{t("general.login_dashboard.next_workshops", "Onze volgende {term}:").replace('{term}', term)}</Typography>
                <ImageList>
                  {(workshops || []).map(workshop => <ImageListItem key={workshop.id} sx={{
                    height: "auto !important",
                    "& > div": {
                      padding: 1
                    }
                  }}>
                    <CardWorkshop
                      image={workshop.get_medium}
                      title={workshop.subject}
                      content={workshop.description}
                      date={DateTime.fromISO(workshop.start_time).toFormat("DD")}
                    />
                  </ImageListItem>)}
                </ImageList>
              </Box>
            </>}

            {!isLogin && <SharedRegister
              objectLoading={objectLoading}
              activePractice={activePractice}
            />}
          </Grid>

          {!matchessm && <Typography onClick={() => {
            setClicks(clicks + 1);
          }} variant="caption" sx={{
            marginTop: 3,
            display: 'block',
            width: '100%'
          }}>{t("shared.terms.copyright")}</Typography>}

          {!matchessm && (showLangPicker || clicks > 9) && <Box sx={{
            width: '100%',
            paddingTop: 1.25,
            textAlign: 'right'
          }}>
            <LanguagePicker />
          </Box>}
        </Paper>

        {!!matchessm && <VersionButton
          sx={{
            position: "absolute",
            right: 24,
            top: 24,
            width: 60,
            height: 60
          }}
          url="https://www.nutriportal.be/"
        />}
      </Box>
    </Box>

    <SnackAppointmentMade open={showAppointmentSuccess} setOpen={setShowAppointmentSuccess} />
  </Box>);
}
