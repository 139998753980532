import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import BackupTableIcon from '@mui/icons-material/BackupTable';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import MedicationIcon from '@mui/icons-material/Medication';

import {
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Button,
  Page,
  Typography,
  // Typography
} from '../../../components/v2/styled';
import { CoachUserIntakeAdvices } from './intake/Advices';
import { CoachUserIntakeAdviceUploads } from './intake/AdviceUploads';
import { CoachUserIntakeIntake } from './intake/Intake';
import { CoachUserIntakeTreatmentPlan } from './intake/TreatmentPlan';

import { User } from '../../../models/User';

import HelpersPractice from '../../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const CoachUserIntake: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  const { state } = useLocation();
  const params = useParams();

  const [currentTab, setCurrentTab] = useState<number>(!!state && !!state.tab ? state.tab : 0);
  // open_body -> intake

  // open_advice: advice.id,
  // active_advice: advice

  // open_treatment_plans: treatmentPlans.custom_result[0].id, 
  // active_treatment_plan: treatmentPlans.custom_result[0]
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [consentAsked, setConsentAsked] = useState<string>();

  let is_nutri_advice = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_advice'});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["user", 'short_consent', (currentPractice?.id || login?.practice_id), objectId],
    queryFn: () =>
      fetch_one<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'user',
          id: objectId,
          fields: [
            'id', 'first_name', 'last_name', 'consent_status'
          ],
          practice_id: (currentPractice?.id || login?.practice_id)
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
      };
    },
    enabled: !!(currentPractice?.id || login?.practice_id) && parseInt(objectId || '', 10) > 0,
  });





  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      pass?: boolean;
      consent?: boolean;
      group?: boolean;
    }) => {
      return fetch_one<{custom_result: {success?: boolean; url?: string; email?: string;}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setConsentAsked(data.custom_result.success ? 'asked' : 'error');
    },
  });






  return <Page
  title={
    currentTab === 0 ? t("client.file.intake_profile") : 
    currentTab === 1 ? t("client.file.treatment_plan") : 
    currentTab === 2 ? t("client.file.advices") : 
    currentTab === 3 ? t("client.file.advice_uploads") : ''}
  titleCrumbs={[
    {
      title: t("coach.menu.users", "Cliënten"),
      path: "/users"
    },
    {
      title: !!object?.first_name ? [object?.first_name, object?.last_name].join(" ") : 'Cliënt',
      path: `/user/${object?.id}/detail`
    }
  ]}
  premiumGatekeeping={!!is_nutri_advice}
  premiumMode='nutri_advice'
>
    <Box>
      <Box sx={{
        marginBottom: 3,
        '& .Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
      }}>
        <Tabs
          value={currentTab}
          onChange={(e, v) => {setCurrentTab(v);}}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Filters"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab icon={<BackupTableIcon />} label={t("client.file.intake_profile")} />
          <Tab icon={<MedicationIcon />} label={t("client.file.treatment_plan")} />
          <Tab icon={<EscalatorWarningIcon />} label={t("client.file.advices")} />
          <Tab icon={<ImageSearchIcon />} label={t("client.file.advice_uploads")} />
        </Tabs>
        <Divider sx={{borderColor: theme.palette.primary.main}} />
      </Box>


      {currentTab === 0 && !!object && !!object.consent_status?.is_intake && <CoachUserIntakeIntake
        openBody={!!state && !!state.open_body}
        user={object}
      />}
      {currentTab === 1 && !!object && !!object.consent_status?.is_intake && <CoachUserIntakeTreatmentPlan
        active={!!state && !!state.active_treatment_plan ? state.active_treatment_plan : undefined}
        user={object}
      />}
      {currentTab === 2 && !!object && !!object.consent_status?.is_intake && <CoachUserIntakeAdvices
        // active={!!state && !!state.active_advice ? state.active_advice : undefined}
        user={object}
      />}
      {currentTab === 3 && !!object && !!object.consent_status?.is_intake && <CoachUserIntakeAdviceUploads
        user={object}
      />}

      {!!object && !object.consent_status?.is_intake && <>
        <Typography html sx={{
          border: '1px solid transparent',
          borderColor: theme.palette.warning.main,
          padding: 1,
          borderRadius: 1,
          marginTop: 1
        }}>{t("coach.consents.no_access")}</Typography>
        {!object.consent_status?.is_basic && !consentAsked && <Grid item xs={12}>
          <Button
            label={t("coach.general.buttons.ask_consent")}
            id='ask_consent'
            contained
            onClick={(v) => {
              mutationCustomAction.mutate({
                formData: {
                  object: 'user',
                  class_action: 'custom_api_ask_consent',
                  email: object?.email,
                  practice_id: (currentPractice?.id || login?.practice_id)
                }
              });
            }}
            sx={{marginTop: 2}}
          />
        </Grid>}
        {!object.consent_status?.is_basic && !!consentAsked && <Grid item xs={12}>
          <Typography>{t("coach.paragraphs.existing_user_consent_asked")}</Typography>
        </Grid>}
      </>}
    </Box>
  </Page>;
}

