import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';

import {
  Box,
  Button as Btn,
  IconButton,
  Tooltip
} from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Typography
} from './styled';

type Props = {
  sx?: object;
  weight: number;
  setWeight: (n:number) => void;
  disabled?: boolean;
};

export const WeightInput: FunctionComponent<Props> = ({sx, weight, setWeight, disabled}) => {
  // const {t, i18n} = useTranslation(['translations']);

  const [digitHundreds, setDigitHundreds] = useState<number>(0);
  const [digitTens, setDigitTens] = useState<number>(0);
  const [digitSingles, setDigitSingles] = useState<number>(0);
  const [digitCommaSingles, setDigitCommaSingles] = useState<number>(0);
  
  useEffect(() => {
    setDigitHundreds(Math.floor(weight / 100));
    setDigitTens(Math.floor((weight / 10) % 10));
    setDigitSingles(Math.floor(weight % 10));
    setDigitCommaSingles(parseInt(weight.toFixed(1).split(".")[1], 10));
    // console.log(weight);
  }, [weight]);

  return (<>
    <Box sx={{
      display: 'inline-block'
    }}>
      {!disabled && <Btn onClick={(e) => {e.preventDefault(); setWeight(weight + 100);}}><ExpandLessIcon /></Btn>}
      <Typography sx={{fontSize: "3rem"}}>{digitHundreds}</Typography>
      {!disabled && <Btn onClick={(e) => {e.preventDefault(); setWeight(weight - 100);}}><ExpandMoreIcon /></Btn>}
    </Box>
    <Box sx={{
      display: 'inline-block'
    }}>
      {!disabled && <Btn onClick={(e) => {e.preventDefault(); setWeight(weight + 10);}}><ExpandLessIcon /></Btn>}
      <Typography sx={{fontSize: "3rem"}}>{digitTens}</Typography>
      {!disabled && <Btn onClick={(e) => {e.preventDefault(); setWeight(weight - 10);}}><ExpandMoreIcon /></Btn>}
    </Box>
    <Box sx={{
      display: 'inline-block'
    }}>
    {!disabled && <Btn onClick={(e) => {e.preventDefault(); setWeight(weight + 1);}}><ExpandLessIcon /></Btn>}
      <Typography sx={{fontSize: "3rem"}}>{digitSingles}</Typography>
      {!disabled && <Btn onClick={(e) => {e.preventDefault(); setWeight(weight - 1);}}><ExpandMoreIcon /></Btn>}
    </Box>
    <Box sx={{
      display: 'inline-block'
    }}>
      {!disabled && <Btn onClick={(e) => {e.preventDefault();}}>&nbsp;</Btn>}
      <Typography sx={{fontSize: "3rem"}}>.</Typography>
      {!disabled && <Btn onClick={(e) => {e.preventDefault();}}>&nbsp;</Btn>}
    </Box>
    <Box sx={{
      display: 'inline-block'
    }}>
    {!disabled && <Btn onClick={(e) => {e.preventDefault(); setWeight(weight + 0.1);}}><ExpandLessIcon /></Btn>}
      <Typography sx={{fontSize: "3rem"}}>{digitCommaSingles}</Typography>
      {!disabled && <Btn onClick={(e) => {e.preventDefault(); setWeight(weight - 0.1);}}><ExpandMoreIcon /></Btn>}
    </Box>
  </>);
}