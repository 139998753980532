import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router-dom';
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_one, fetch_all } from "../../lib/v31lib";
// import { loginAtom } from '../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import BackupTableIcon from '@mui/icons-material/BackupTable';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import MedicationIcon from '@mui/icons-material/Medication';

import {
  Box,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Page,
  // Typography
} from '../../components/v2/styled';
import { ClientIntakeAdvice } from './intake/Advice';
import { ClientIntakeAdviceUploads } from './intake/AdviceUploads';
import { ClientIntakeIntake } from './intake/Intake';
import { ClientIntakeTreatmentPlan } from './intake/TreatmentPlan';

// const { DateTime } = require("luxon");

type Props = {}

export const ClientIntake: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  const { state } = useLocation();

  const [currentTab, setCurrentTab] = useState<number>(!!state && !!state.tab ? state.tab : 0);
  // open_body -> intake

  // open_advice: advice.id,
  // active_advice: advice

  // open_treatment_plans: treatmentPlans.custom_result[0].id, 
  // active_treatment_plan: treatmentPlans.custom_result[0]







  return <Page>
    <Box>
      <Box sx={{
        marginBottom: 3,
        '& .Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
      }}>
        <Tabs
          value={currentTab}
          onChange={(e, v) => {setCurrentTab(v);}}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Filters"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab icon={<BackupTableIcon />} label={t("client.file.intake_profile")} />
          <Tab icon={<MedicationIcon />} label={t("client.file.treatment_plan")} />
          <Tab icon={<EscalatorWarningIcon />} label={t("client.file.advices")} />
          <Tab icon={<ImageSearchIcon />} label={t("client.file.advice_uploads")} />
        </Tabs>
        <Divider sx={{borderColor: theme.palette.primary.main}} />
      </Box>


      {currentTab === 0 && <ClientIntakeIntake
        openBody={!!state && !!state.open_body}
      />}
      {currentTab === 1 && <ClientIntakeTreatmentPlan
        active={!!state && !!state.active_treatment_plan ? state.active_treatment_plan : undefined}
      />}
      {currentTab === 2 && <ClientIntakeAdvice
        active={!!state && !!state.active_advice ? state.active_advice : undefined}
      />}
      {currentTab === 3 && <ClientIntakeAdviceUploads />}
    </Box>
  </Page>;
}

