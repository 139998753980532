import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LaunchIcon from '@mui/icons-material/Launch';
import TagIcon from '@mui/icons-material/Tag';

import {
  Avatar,
  Tooltip,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Dt,
  Fab,
  IconButton,
  Page
} from '../../../components/v2/styled';
import { SearchBox } from '../../../components/v2/SearchBox';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';

import { NewsItem } from '../../../models/NewsItem';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachNewsItems: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [search, setSearch] = useState<string>("");

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "news_items", (currentPractice?.id || login?.practice_id), {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<NewsItem>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'news_item',
          fields: ['id', 'published_at', 'title', 'get_item', 'is_coach_only', 'tags'],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              for_practice: (currentPractice?.id || login?.practice_id)
            }
          },
          skip_api_restrictions: 1
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(currentPractice?.id || login?.practice_id),
  });







  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<NewsItem>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      objectsRefetch();
    },
  });








  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'get_item', headerName: t('coach.models.news_items.item', "Afbeelding"), width: 100, sortable: false, renderCell: params => <>
      {!!params.row.get_item && <Avatar src={params.row.get_item} sx={{marginTop: 0.5}} />}
    </> },
    { field: 'title', headerName: t("coach.models.news_items.title"), width: 250 },
    { field: 'published_at', headerName: t("coach.models.news_items.published_at"), width: 200, renderCell: params => <>
      <Dt d={params.row.published_at} f="DDD t" />
    </> },
    { field: 'is_coach_only', headerName: t("coach.models.news_items.is_coach_only"), width: 200, renderCell: params => <>
      {!!params.row.is_coach_only && <CheckIcon />}
      {!params.row.is_coach_only && <CloseIcon />}
    </> },
    { field: 'tags', headerName: t("coach.models.news_items.tags"), width: 100, renderCell: params => <>
      {!!params.row.tags && <Tooltip title={params.row.tags}><TagIcon /></Tooltip>}
    </> },
  ];

  return <Page title={t("coach.menu.news_items")}>
    <SearchBox
      search={search}
      setSearch={setSearch}
      objectCount={count}
    />
    <DataGrid
      action_count={3}
      hideCount
      actions={(params) => {
        return (<>
          <IconButton title="View" color="default" onClick={() => {
            window.open(`/news/${params.row.id}`, "_BLANK");
          }}>
            <LaunchIcon />
          </IconButton>
          <IconButton title="Edit" color="default" path={`/news_item/${params.row.id}`}>
            <EditIcon />
          </IconButton>
          <IconButton title="Remove" destroyBtn color="default" onClick={() => {
            mutationDestroy.mutate({
              formData: {
                object: 'news_item',
                id: params.row.id,
                ob: {
                  id: params.row.id,
                }
              }
            });
          }}>
            <DeleteIcon />
          </IconButton>
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />

    <Fab
      onClick={() => {
        navigate("/news_item/new", {});
      }}
    />
  </Page>;
}
