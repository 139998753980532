import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Avatar,
  Box,
  Divider
} from '@mui/material';

import {
  Dt,
  Typography
} from './styled';

type Props = {
  url: string;
  file_name: string;
  practice?: string;
  date?: string;
};

export const AttachmentSimple: FunctionComponent<Props> = ({url, file_name, practice, date}) => {
  const theme = useTheme();

  return (<Box sx={{
    cursor: 'pointer',
    padding: 1,
    border: '1px solid transprent',
    borderColor: theme.palette.primary.main,
    display: 'inline-block',
    borderRadius: 5,
    marginRight: 1,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)'
    }
  }} onClick={(e) => {
    e.preventDefault();
    window.open(url);
  }}>
    <Box sx={{
      fontSize: '0.9rem',
      textAlign: 'center'
    }}>
      <Avatar sx={{
        maxWidth: 100,
        maxHeight: 100,
        height: 'auto',
        width: 'auto',
        display: 'inline-block'
      }} variant="square" src={url} />
    </Box>
    <Box sx={{
      fontSize: '0.9rem',
      textAlign: 'center',
      '& span': {
        maxWidth: '100%',
        display: 'block'
      }
    }}>
      {!!practice && <Typography sx={{fontWeight: 'bold'}}>{practice}</Typography>}
      {!!date && <Dt d={date} f="DDD" />}
      {(!!practice || !!date) && <Divider />}
      <Typography>{file_name}</Typography>
    </Box>
  </Box>);
}
