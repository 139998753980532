export default class Practice {

  static practice_modules_v3 = ({
    practice, check
  }) => {
    let is_nutri_appointment = false;
    let is_nutri_workshop = false;
    let is_nutri_measures_weight = false;
    let is_nutri_diary_eat = false;
    let is_nutri_diary_move = false;
    let is_nutri_advice = false;
    let is_nutri_mail = false;
    let is_payments = false;
    let is_video = false;
    let is_ehealth = false;

    if (practice && practice.modules) {
      practice.modules.forEach(e => {
        if (!is_nutri_appointment) is_nutri_appointment = e.id === 1;
        if (!is_nutri_workshop) is_nutri_workshop = e.id === 2;
        if (!is_nutri_measures_weight) is_nutri_measures_weight = e.id === 3;
        if (!is_nutri_diary_eat) is_nutri_diary_eat = e.id === 10;
        if (!is_nutri_diary_move) is_nutri_diary_move = e.id === 11;
        if (!is_nutri_advice) is_nutri_advice = e.id === 12;
        if (!is_nutri_mail) is_nutri_mail = e.id === 13;
        if (!is_payments) is_payments = e.id === 15;
        if (!is_video) is_video = e.id === 16;
        if (!is_ehealth) is_ehealth = e.id === 17;
      });
    }

    if (check === 'nutri_appointment') {
      return is_nutri_appointment;
    } else if(check === 'nutri_workshop') {
      return is_nutri_workshop;
    } else if(check === 'nutri_measures_weight') {
      return is_nutri_measures_weight;
    } else if(check === 'nutri_diary') {
      return is_nutri_diary_eat || is_nutri_diary_move;
    } else if(check === 'nutri_diary_eat') {
      return is_nutri_diary_eat;
    } else if(check === 'nutri_diary_move') {
      return is_nutri_diary_move;
    } else if(check === 'nutri_mail') {
      return is_nutri_mail;
    } else if(check === 'payments') {
      return is_payments;
    } else if(check === 'video') {
      return is_video;
    } else if(check === 'ehealth') {
      return is_ehealth;
    } else if(check === 'nutri_advice') {
      return is_nutri_advice;
    } else {
      return {
        nutri_appointment: is_nutri_appointment,
        nutri_workshop: is_nutri_workshop,
        measures_weight: is_nutri_measures_weight,
        nutri_diary: is_nutri_diary_eat || is_nutri_diary_move,
        nutri_diary_eat: is_nutri_diary_eat,
        nutri_diary_move: is_nutri_diary_move,
        nutri_mail: is_nutri_mail,
        payments: is_payments,
        video: is_video,
        nutri_advice: is_nutri_advice,
        ehealth: is_ehealth
      }
    }
  };

  static practice_modules = ({
    practice, check
  }) => {
    let is_nutri_appointment = false;
    let is_nutri_workshop = false;
    let is_nutri_measures_weight = false;
    let is_nutri_diary_eat = false;
    let is_nutri_diary_move = false;
    let is_nutri_advice = false;
    let is_nutri_mail = false;
    let is_payments = false;
    let is_video = false;

    if (practice && practice.modules) {
      practice.modules.forEach(e => {
        if (!is_nutri_appointment) is_nutri_appointment = e.attributes.module_id === 1;
        if (!is_nutri_workshop) is_nutri_workshop = e.attributes.module_id === 2;
        if (!is_nutri_measures_weight) is_nutri_measures_weight = e.attributes.module_id === 3;
        if (!is_nutri_diary_eat) is_nutri_diary_eat = e.attributes.module_id === 10;
        if (!is_nutri_diary_move) is_nutri_diary_move = e.attributes.module_id === 11;
        if (!is_nutri_advice) is_nutri_advice = e.attributes.module_id === 12;
        if (!is_nutri_mail) is_nutri_mail = e.attributes.module_id === 13;
        if (!is_payments) is_payments = e.attributes.module_id === 15;
        if (!is_video) is_video = e.attributes.module_id === 16;
      });
    }

    if (check === 'nutri_appointment') {
      return is_nutri_appointment;
    } else if(check === 'nutri_workshop') {
      return is_nutri_workshop;
    } else if(check === 'nutri_measures_weight') {
      return is_nutri_measures_weight;
    } else if(check === 'nutri_diary') {
      return is_nutri_diary_eat || is_nutri_diary_move;
    } else if(check === 'nutri_diary_eat') {
      return is_nutri_diary_eat;
    } else if(check === 'nutri_diary_move') {
      return is_nutri_diary_move;
    } else if(check === 'nutri_mail') {
      return is_nutri_mail;
    } else if(check === 'payments') {
      return is_payments;
    } else if(check === 'video') {
      return is_video;
    } else if(check === 'nutri_advice') {
      return is_nutri_advice;
    } else {
      return {
        nutri_appointment: is_nutri_appointment,
        nutri_workshop: is_nutri_workshop,
        measures_weight: is_nutri_measures_weight,
        nutri_diary: is_nutri_diary_eat || is_nutri_diary_move,
        nutri_diary_eat: is_nutri_diary_eat,
        nutri_diary_move: is_nutri_diary_move,
        nutri_mail: is_nutri_mail,
        payments: is_payments,
        video: is_video,
        nutri_advice: is_nutri_advice
      }
    }
  };
}
