import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';

import {
  Box,
  Chip,
  Divider,
  Grid,
} from '@mui/material';

import {
  Button,
  Card,
  Dt,
  Page,
  Typography,
} from '../../components/v2/styled';

import ContentLayoutSwitcher from '../../components/shared/content_layout/Switcher';

import { NewsItem as NI } from '../../models/NewsItem';

import HelpersColours from '../../actions/helpers/colours';

// const { DateTime } = require("luxon");

type Props = {
  coach?: boolean;
}

export const SharedNewsItem: FunctionComponent<Props> = ({coach}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();
  const params = useParams();
  const [objectId, setObjectId] = useState<string | undefined>(params.id);

  // const [currentTab, setCurrentTab] = useState<number>(!!state && !!state.tab ? state.tab : 0);

  const {
    data: newsItem,
    isLoading: newsItemLoading,
    isError: newsItemError,
    isSuccess: newsItemSuccess,
    refetch: newsItemRefetch,
  } = useQuery({
    queryKey: ["news_item", objectId],
    queryFn: () =>
      fetch_one<NI>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'news_item',
          fields: ['id', 'tags', 'published_at', 'publisher', 'author', 'title', 'content', 'content_layout_key', 'content_layout', 'teaser', 'get_item', 'practice_id'],
          id: objectId
        },
        login
      ),
    enabled: !!login && !!objectId,
  });
  const {
    data: newsItems,
    isLoading: newsItemsLoading,
    isError: newsItemsError,
    isSuccess: newsItemsSuccess,
    refetch: newsItemsRefetch,
  } = useQuery({
    queryKey: ["news_items", "client", 'sidebar'],
    queryFn: () =>
      fetch_all<NI>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'news_item',
          fields: ['id', 'published_at', 'publisher', 'title', 'content', 'teaser', 'get_item', 'practice_id'],
          per_page: 2,
          page: 0,
          order: "published_at DESC",
          filter: {
            advanced: {
              practice_id: currentPractice?.id || login?.practice_id,
              is_coach_only: !coach ? 0 : 1,
              skip_id: objectId
            }
          }
        },
        login
      ),
    enabled: !!login,
  });







  useEffect(() => {
    setObjectId(params.id);
  }, [params.id]);
  useEffect(() => {
    newsItemRefetch();
    newsItemsRefetch();
  }, [objectId]);
  
  return <Page sx={{padding: {xs: 0, md: 3}}}>
    {!!newsItemSuccess && !!newsItem && <Grid container spacing={1}>

      <Grid container spacing={1} item xs={12} sm={8} sx={{alignContent: 'baseline'}}>
        <Grid item xs={12}>
          <Button
            id="back_top"
            sx={{
              marginBottom: 2,
              marginTop: 0,
              borderRadius: 2,
              color: theme.palette.primary.main
            }}
            text
            startIcon={<ArrowBackIcon />}
            onClick={(e) => {
              navigate("/news");
            }}
            label={t("general.news.back_btn", "Terug naar alle nieuwsberichten")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h1"}>{newsItem.title}</Typography>
          <Divider sx={{marginBottom: 2}} />
          <Grid container spacing={2}>
            <ContentLayoutSwitcher
              default_value={<Typography html>{newsItem.content}</Typography>}
              cl_key={newsItem.content_layout_key}
              cl={newsItem.content_layout || {}}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1} item xs={12} sm={4} sx={{alignContent: 'baseline', flexBasis: "calc(33.33% + 8px) !important"}}>
        <Grid item xs={12}>
          <Box style={{
            padding: 2,
            backgroundColor: HelpersColours.get_rgba(theme.palette.primary.main, 0.4),
            border: `2px solid ${theme.palette.primary.main}`
          }}>
            <div style={{display: 'flex', alignItems: 'center'}}><PersonIcon style={{marginRight: 8}} /> {newsItem.author}{newsItem.author ? ", " : ""}{newsItem.publisher}</div>
            <div style={{display: 'flex', alignItems: 'center'}}><EventIcon style={{marginRight: 8}} /> <Dt d={newsItem.published_at} f="DDD" /></div>
          </Box>
        </Grid>

        {(newsItem.tags?.split(" ") || []).length > 0 && <Grid item xs={12}>
          {newsItem.tags?.split(" ").map(tag => <Chip color="primary" sx={{marginRight: 1}} label={tag} />)}
        </Grid>}

        {newsItemsSuccess && newsItems.map(item => <Card
          huge
          actionNavFunc={() => {
            navigate(`/news/${item.id}`);
          }}
          actionName={t("admin.dashboard.news.action")}
          media={
            !!item.get_item ? [item.get_item, item.title] : undefined
          }
        >
          <Box sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            padding: '10px 40px',
            ...(item.practice_id ? {
              backgroundColor: theme.palette.tertiary?.main
            } : {
              backgroundColor: '#97BE6B'
            })
          }}>{item.publisher}</Box>
          <Typography variant="h5" sx={{
            marginBottom: 1
          }}>{item.title}</Typography>
          <Typography html>{item.teaser || (item.content ? item.content.substring(0, 100) : "")}</Typography>
        </Card>)}
      </Grid>

    </Grid>}
  </Page>;
}

