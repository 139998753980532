import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAtom, useAtomValue } from 'jotai';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {Route, Routes} from "react-router-dom";

// import { fetch_all, fetch_one } from '../../lib/server_helper';
import { fetch_one } from "../../lib/v31lib";
import { currentPracticeAtom } from '../../lib/practice';
import { loginAtom, set_login } from '../../lib/auth';

import EmailIcon from '@mui/icons-material/Email';

import {
  Box,
  Button as Btn,
  CircularProgress,
  DialogActions,
  DialogContentText,
  Grid
} from '@mui/material';

import {
  Button,
  TextInput,
  Typography
} from '../../components/v2/styled';
import { Alert } from '../../components/v2/dialogs/Alert';
import { Base as DialogBase } from '../../components/v2/dialogs/Base';
import { PracticeLogo } from '../../components/v2/PracticeLogo';

import { Practice } from '../../models/Practice';
import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  showAppointmentSuccess?: boolean;
  activePractice?: Practice;
  objectLoading: boolean;
};

export const SharedLogin: FunctionComponent<Props> = ({activePractice, objectLoading, showAppointmentSuccess}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const matchessm = useMediaQuery(theme.breakpoints.up('sm'));

  let [searchParams, setSearchParams] = useSearchParams();
  const [login, setLogin] = useAtom(loginAtom);
  const currentPractice = useAtomValue(currentPracticeAtom);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState<boolean>(false);
  const [editPasswordOpen, setEditPasswordOpen] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [epPassword, setEpPassword] = useState<string>('');
  const [epPasswordRepeat, setEpPasswordRepeat] = useState<string>('');

  const [isLoginError, setIsLoginError] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<{
    success: boolean;
    data: any;
  }>();
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState<boolean>(false);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState<boolean>(false);
  const [isEditPasswordError, setIsEditPasswordError] = useState<boolean>(false);
  const [editPasswordLoading, setEditPasswordLoading] = useState<boolean>(false);
  const [editPasswordSuccess, setEditPasswordSuccess] = useState<boolean>(false);
  const [editPasswordError, setEditPasswordError] = useState<{
    success: boolean;
    data: any;
  }>();

  const mutationEditPassword = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/users/edit_password`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      setIsEditPasswordError(false);
      setEditPasswordSuccess(false);
      setEditPasswordError(undefined);
      setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      setIsEditPasswordError(true);
      setEditPasswordError(data as any);
      setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setEditPasswordSuccess(true);
      setEditPasswordLoading(false);
      // navigate('/');
      // setObject(data as CouchRow);
      // queryClient.invalidateQueries({ queryKey: ["rows", router.query.row_id] });
      // router.push(`/scheme/${router.query.slug}/${router.query.id}/rows`);
    },
  });

  const mutationForgotPassword = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/users/forgot_password`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      setForgotPasswordSuccess(false);
      setForgotPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      setForgotPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setForgotPasswordSuccess(true);
      setForgotPasswordLoading(false);
      // navigate('/');
      // setObject(data as CouchRow);
      // queryClient.invalidateQueries({ queryKey: ["rows", router.query.row_id] });
      // router.push(`/scheme/${router.query.slug}/${router.query.id}/rows`);
    },
  });

  const mutationLogin = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/users/login`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      setIsLoginError(false);
      setLoginError(undefined);
      return true;
    },
    onError: (data, variables, context) => {
      setIsLoginError(true);
      setLoginError(data as any);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setLogin(data);
      set_login(data.email, data.auth_token);
      navigate('/');
    },
  });

  useEffect(() => {
    if (!!searchParams.get('t')) setEditPasswordOpen(true);
  }, [searchParams]);

  return (<>
    <Grid sx={{
      alignItems: "center",
      textAlign: "center",
      position: "relative",
      paddingRight: `24px !important`
    }} container item sm={6} xs={12}>
      {!!matchessm && <Grid item xs={12}>
        <PracticeLogo practice={currentPractice} />
      </Grid>}
      {!!objectLoading && <Grid item xs={12}>
        <CircularProgress />
      </Grid>}
      {!objectLoading && <Grid item xs={12}>
        {!!activePractice && !!activePractice.get_my_practice && <Typography html>{activePractice.get_my_practice}</Typography>}
        {!(!!activePractice && !!activePractice.get_my_practice) && <>
          <Typography variant="h1" sx={{
            textAlign: 'center',
            marginBottom: 1
          }}>{t("shared.signin.info.title")}</Typography>
          <Typography html sx={{
            textAlign: 'center'
          }}>{t("shared.signin.info.text")}</Typography>
        </>}
      </Grid>}

      <Box sx={{
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
          height: "100%",
          top: 0,
          right: 0,
          width: '1px'
        },
        [theme.breakpoints.down('sm')]: {
          width: "100%",
          bottom: 0,
          left: 0,
          height: '1px'
        }
      }}></Box>


    </Grid>
    <Grid sx={{
      flexDirection: "column",
      paddingLeft: `24px !important`
    }} item sm={6} xs={12}>
      <Box sx={{
        marginBottom: 3,
        [theme.breakpoints.down('sm')]: {
          marginTop: 3
        }
      }} id="form">
        
        <Typography variant="h1" sx={{textAlign: 'center', marginBottom: 1, color: theme.palette.primary.main}}>
          {t("shared.buttons.sign_in")}
        </Typography>

        <Typography sx={{textAlign: 'justify', color: theme.palette.secondary.main}}>
          {t("shared.signin.info.new_system")}
        </Typography>

        {!!showAppointmentSuccess && <Typography sx={{textAlign: 'justify', color: theme.palette.primary.main, marginTop: 2, padding: 1, border: '1px solid transparent', borderColor: theme.palette.primary.main, borderRadius: 3}}>
          {t("shared.signin.info.appointment_created")}
        </Typography>}

        <Grid container spacing={1}>
          {mutationLogin.isPending && <Grid item xs={12} sx={{
            textAlign: "center",
            height: 150
          }}>
            <CircularProgress sx={{margin: 2}} />
          </Grid>}
          {!mutationLogin.isPending && <>
            <Grid item xs={12}>
              <TextInput
                error={!!isLoginError ? t("email_error") : undefined}
                muiIcon={<EmailIcon
                  sx={{
                    color: "rgb(195,198,197)"
                  }}
                />}
                id="email"
                placeholder={t("shared.signin.field.email")}
                autoComplete="email"
                autoFocus={false}
                value={email}
                onChange={(v) => {setEmail(v);}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                error={!!isLoginError ? t("password_error") : undefined}
                icon={"fa-key"}
                id="password"
                placeholder={t("shared.signin.field.password")}
                type="password"
                autoComplete="current-password"
                autoFocus={false}
                value={password}
                onChange={(v) => {setPassword(v);}}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                label={t("shared.buttons.sign_in")}
                id='login'
                contained
                disabled={!email || !password || !(!!email && email.indexOf('@') > -1 && email.indexOf('.') > -1)}
                fullwidth
                onClick={(v) => {
                  mutationLogin.mutate({
                    formData: {
                      fields: loginFields,
                      email: email,
                      password: password
                    }
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                label={t("shared.buttons.register")}
                id='register'
                text
                fullwidth
                onClick={(v) => {
                  navigate("/register");
                  // @ts-ignore
                  if(!!document.getElementById("subscroller")) document.getElementById("subscroller").scrollTo(0, 0);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                label={t("shared.buttons.forgot_password")}
                id='forgot_pass'
                text
                fullwidth
                onClick={(v) => {
                  setForgotPasswordOpen(true);
                }}
              />
            </Grid>

            <Alert
              hidden={!(isLoginError && loginError?.data === 'Disabled user')}
              title={t("shared.signin.error.title")}
              content={t("shared.signin.error.text_disabled")}
            />
            <Alert
              hidden={!(isLoginError && loginError?.data === 'Unconfirmed user')}
              title={t("shared.signin.error.title")}
              content={t("shared.signin.error.text_unconfirmed")}
            />
            <Alert
              hidden={!(isLoginError && loginError?.data !== 'Disabled user' && loginError?.data !== 'Unconfirmed user')}
              title={t("shared.signin.error.title")}
              content={t("shared.signin.error.text")}
            />
          </>}
        </Grid>

        {!!activePractice?.settings?.is_allow_guest_accounts && <>
          <Box>
            <Box sx={{
              backgroundColor: theme ? theme.palette.primary.main : 'red',
              width: "100%",
              bottom: 0,
              left: 0,
              height: 1,
              position: "relative"
            }}>
              <Box sx={{
                height: 20,
                top: -10,
                width: "100%",
                display: "block",
                position: "absolute"
              }}>
                <Typography sx={{
                  display: "block",
                  width: 40,
                  margin: "0 auto",
                  backgroundColor: "white",
                  textAlign: "center"
                }}>{t("shared.terms.or")}</Typography>
              </Box>
            </Box>
            <Button
              id="app_btn"
              contained
              fullwidth
              onClick={() => {
                navigate("/appointment")
              }}
              label={t("shared.buttons.appointment")}
            />
          </Box>
        </>}


      </Box>
    </Grid>

    <DialogBase
      name="fp"
      loading={forgotPasswordLoading}
      title={t("shared.forgot_password.info.title")}
      content={<>
        {!forgotPasswordSuccess && <>
          <DialogContentText id="fp-dialog-description">
            <Typography>{t("shared.forgot_password.info.text")}</Typography>
          </DialogContentText>
          <TextInput
            id="fp_email"
            placeholder={t("shared.signin.field.email")}
            autoComplete="email"
            autoFocus
            margin="dense"
            value={email}
            onChange={(v) => {
              setEmail(v);
            }}
          />
          {!!email && !(email && email.indexOf('@') > -1 && email.indexOf('.') > -1) && <Typography sx={{
            color: theme.palette.warning.main,
            fontStyle: 'italic'
          }}>{t('shared.forgot_password.error.invalid_email')}</Typography>}
        </>}
        {!!forgotPasswordSuccess && <DialogContentText id="fp-dialog-description">
          <Typography>{t("shared.forgot_password.success.text")}</Typography>
        </DialogContentText>}
      </>}
      actions={<>
        {!forgotPasswordSuccess && <DialogActions>
          <Btn onClick={() => {setForgotPasswordOpen(false);}}>{t("shared.buttons.cancel")}</Btn>
          <Btn variant="contained" onClick={() => {
            mutationForgotPassword.mutate({
              formData: {
                email: email
              }
            });
          }}>{t("shared.buttons.confirm")}</Btn>
        </DialogActions>}
        {!!forgotPasswordSuccess && <DialogActions>
          <Btn onClick={() => {setForgotPasswordOpen(false);}}>{t("shared.buttons.close")}</Btn>
        </DialogActions>}
      </>}
      open={forgotPasswordOpen}
      setOpen={setForgotPasswordOpen}
    />

    <DialogBase
      name="ep"
      loading={editPasswordLoading}
      title={t("shared.forgot_password.info.title")}
      content={<>
        {!editPasswordSuccess && <>
          <DialogContentText id="ep-dialog-description">
            <Typography>{t("shared.forgot_password.edit.text")}</Typography>
          </DialogContentText>
          <TextInput
            id="ep_password"
            type="password"
            placeholder={t("shared.signup.field.password")}
            helperText={t("shared.signup.field.password_tip")}
            autoFocus
            required
            margin="dense"
            value={epPassword}
            onChange={(v) => {
              setEpPassword(v);
            }}
            error={!!isEditPasswordError ? 'err' : undefined}
          />
          <TextInput
            id="ep_password_repeat"
            type="password"
            placeholder={t("shared.signup.field.password_repeat")}
            required
            margin="dense"
            value={epPasswordRepeat}
            onChange={(v) => {
              setEpPasswordRepeat(v);
            }}
            error={!!isEditPasswordError ? 'err' : undefined}
          />
        </>}
        {!!editPasswordError && <DialogContentText id="ep-dialog-description">
          <Typography sx={{marginTop: 2.5, color: theme.palette.secondary.main}}>{t("shared.forgot_password.edit_error.text")}</Typography>
        </DialogContentText>}
        {!!editPasswordSuccess && <DialogContentText id="ep-dialog-description">
          <Typography>{t("shared.forgot_password.edit_success.text")}</Typography>
        </DialogContentText>}
      </>}
      actions={<>
        {!editPasswordSuccess && <DialogActions>
          <Btn onClick={() => {setEditPasswordOpen(false);}}>{t("shared.buttons.cancel")}</Btn>
          <Btn variant="contained" onClick={() => {
            mutationEditPassword.mutate({
              formData: {
                password: epPassword,
                password_confirmation: epPasswordRepeat,
                t: searchParams.get('t')
              }
            });
          }}>{t("shared.buttons.save")}</Btn>
        </DialogActions>}
        {!!editPasswordSuccess && <DialogActions>
          <Btn onClick={() => {setEditPasswordOpen(false);}}>{t("shared.buttons.close")}</Btn>
        </DialogActions>}
      </>}
      open={editPasswordOpen}
      setOpen={setEditPasswordOpen}
    />
  </>);
}
