import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';

import {
  CardActions,
  CardContent,
  CardMedia,
} from '@mui/material';

import EventIcon from '@mui/icons-material/Event';

import {
  Typography
} from '../../../components/v2/styled';
import { CardBase } from './Base';

type Props = {
  image?: string;
  title: string;
  content: string;
  date: string;
};

export const CardWorkshop: FunctionComponent<Props> = ({image, title, content, date}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();

  return (<CardBase>
    {image && <CardMedia
      component="img"
      sx={{
        height: 100
      }}
      image={image}
      alt="Workshop"
    />}
    <CardContent>
      <Typography variant="subtitle2" sx={{
        marginBottom: 2,
        textAlign: 'left'
      }}>{title}</Typography>
      <Typography html sx={{
        textAlign: 'left'
      }}>{content}</Typography>
    </CardContent>
    <CardActions>
      <Typography sx={{
        textAlign: 'left',
        marginTop: 1,
        marginLeft: 1
      }}>
        <EventIcon sx={{
          marginRight: 0.5,
          marginBottom: -0.5
        }} />{date}
      </Typography>

    </CardActions>
  </CardBase>);
}