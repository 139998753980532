import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { setCookie } from 'react-use-cookie';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  AppBar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Button,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Practice } from '../../../models/Practice';
import { User } from '../../../models/User';

import HelpersLanguage from '../../../actions/helpers/language';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachGeneralSettingsPayments: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [extendedPractice, setExtendedPractice] = useState<Practice>();
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [currentLanguageId, setCurrentLanguageId] = useState<number>(1);

  const fields = [
    'id', 'name', 'extra_data', 'full_mollie_description', 'mollie_api_key', 'mollie_coach_api_keys'
  ];

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: extendedPracticeTemp,
    isLoading: extendedPracticeLoading,
    isError: extendedPracticeError,
    isSuccess: extendedPracticeSuccess,
    refetch: aextendedPracticeRefetch,
  } = useQuery({
    queryKey: ["general_settings_pay", currentPractice?.id],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: fields,
          id: currentPractice?.id
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!currentPractice?.id,
  });








  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      aextendedPracticeRefetch();
      setSavedOpen(true);
    },
  });








  const saveUser = () => {
    if (!!extendedPractice) {
      let trans:{[s:string]: {[n:number]: string}} = {
        'mollie_description': {}
      };
      Object.keys(extendedPractice.full_mollie_description || {}).filter(fff => fff.toString() !== '1').forEach(mm => {
        trans['mollie_description'][parseInt(mm, 10)] = (extendedPractice.full_mollie_description || {})[parseInt(mm, 10)];
      })

      mutationSave.mutate({
        formData: {
          object: 'practice',
          fields: fields,
          id: extendedPractice.id,
          ob: {
            id: extendedPractice.id,
            mollie_api_key: extendedPractice.mollie_api_key,
            mollie_description: (extendedPractice.full_mollie_description || {})[1] || '',
            mollie_coach_api_keys: extendedPractice.mollie_coach_api_keys
          },
          translations: trans
        }
      });
    }
  }

  useEffect(() => {
    if (!!extendedPracticeTemp) {
      setExtendedPractice(extendedPracticeTemp);
    }
  }, [extendedPracticeTemp]);

  return <Grid container spacing={2}>
    {!!extendedPracticeLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!extendedPractice && <>
      <Grid item xs={12} container spacing={2} sx={{alignContent: 'baseline'}}>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("coach.settings.payments.mollie.title_practice")}</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="mollie_api_key"
                  caption={t("coach.models.practices.mollie_api_key", "Mollie API Key")}
                  placeholder={t("coach.models.practices.placeholders.mollie_api_key", "live_abcd1234")}
                  value={extendedPractice.extra_data?.workshop_settings?.singular || ''}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      mollie_api_key: e
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                <AppBar position="static" sx={{backgroundColor: theme.palette.primary.contrastText}}>
                  <Tabs value={currentLanguageId} onChange={(ev, i) => {
                    setCurrentLanguageId(i);
                  }}>
                    {HelpersLanguage.available_locales().map((locale, i) => {
                      return (<Tab value={HelpersLanguage.mapping_to_id(locale)} label={locale.toUpperCase()} id={`locale_tab_${locale}`} />)
                    })}
                  </Tabs>
                </AppBar>
              </Grid>
              <Grid item xs={12} sx={{borderLeft: "4px solid rgba(0, 0, 0, 0.54)"}}>
                <TextInput
                  id="subject"
                  caption={t("coach.models.practices.mollie_description", "Client omschrijving")}
                  placeholder={t("coach.models.practices.placeholders.mollie_description", "De praktijk voorziet de mogelijkheid om deze afspraak online te betalen. De betaal-link kan u terugvinden in de bevestigings-email die verzonden zal worden na het bevestigen onderaan.")}
                  value={(extendedPractice?.full_mollie_description || {})[currentLanguageId] || ""}
                  
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      full_mollie_description: {
                        ...(extendedPractice.full_mollie_description || {}),
                        [currentLanguageId]: e
                      }
                    });
                  }}
                />
              </Grid>
                
              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!!mutationSave.isPending && <CircularProgress />}
                {!mutationSave.isPending && <Button
                  id="save_profile"
                  disabled={!extendedPractice.name}
                  contained
                  onClick={(e) => {
                    saveUser();
                  }}
                  label={t("client.general.buttons.submit", "Opslaan")}
                  sx={{
                    marginTop: 1
                  }}
                />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("coach.settings.payments.mollie.title_coach")}</Typography>
                <Divider />
              </Grid>
              {(coaches || []).map(coach => <Grid item xs={12}>
                <TextInput
                  id="mollie_api_key"
                  caption={`Mollie API Key (${coach.full_name})`}
                  placeholder={t("coach.models.practices.placeholders.mollie_api_key", "live_abcd1234")}
                  value={(extendedPractice.mollie_coach_api_keys || {})[coach.id || 0] || ''}
                  onChange={(e) => {
                    setExtendedPractice({
                      ...extendedPractice,
                      mollie_coach_api_keys: {
                        ...(extendedPractice.mollie_coach_api_keys || {}),
                        [coach.id || 0]: e
                      }
                    });
                  }}
                />
              </Grid>)}
                
              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!!mutationSave.isPending && <CircularProgress />}
                {!mutationSave.isPending && <Button
                  id="save_profile"
                  disabled={!extendedPractice.name}
                  contained
                  onClick={(e) => {
                    saveUser();
                  }}
                  label={t("client.general.buttons.submit", "Opslaan")}
                  sx={{
                    marginTop: 1
                  }}
                />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

      </Grid>
    </>}

    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Grid>;
}

