import React, { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all_with_count } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
  Grid,
  Paper,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Fab,
  IconButton,
} from '../../../components/v2/styled';
import { CoachLocationEdit } from '../../../components/v2/dialogs/CoachLocationEdit';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Location } from '../../../models/Location';
import { Practice } from '../../../models/Practice';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachGeneralSettingsLocations: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [search, setSearch] = useState<string>("");

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);

  const [savedOpen, setSavedOpen] = useState<boolean>(false);

  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [activeLocation, setActiveLocation] = useState<Location>();

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "locations", (currentPractice?.id || login?.practice_id), {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<Location>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'location',
          fields: [
            'id', 'name', 'extra_data', 'street', 'number', 'zip', 'city', 'country_id', 'coaches', 'enabled', 'get_medium', 'lat', 'lng',
            'is_online', 'is_hidden', 'invisible_coaches', 'background_colour', 'get_bg_item', 'is_background_colour'
          ],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id)
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(currentPractice?.id || login?.practice_id),
  });







  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      objectsRefetch();
    },
  });
  const mutationSaveAppType = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectsRefetch();
      setEditOpen(false);
      setSavedOpen(true);
    },
  });








  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'name', headerName: t('coach.models.locations.name'), width: 250, sortable: true },
    { field: 'address', headerName: t('coach.models.locations.address'), width: 400, sortable: false },
  ];

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Paper sx={{
        padding: 2
      }}>
        <DataGrid
          action_count={2}
          hideCount
          actions={(params) => {
            return (<>
              <IconButton title="Edit" color="default" onClick={() => {
                setActiveLocation(params.row);
                setEditOpen(true);
              }}>
                <EditIcon />
              </IconButton>
              <IconButton title="Remove" destroyBtn color="default" onClick={() => {
                mutationDestroy.mutate({
                  formData: {
                    object: 'location',
                    id: params.row.id,
                    ob: {
                      id: params.row.id,
                    }
                  }
                });
              }}>
                <DeleteIcon />
              </IconButton>
            </>);
          }}
          objectCount={count}
          getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
          objects={(objects || []).map(u => ({
            ...u,
            address: [[u.street, u.number].join(' '), [u.zip, u.city].join(' ')].join(", ")
          }))}
          columns={columns}
          loader={objectsLoading}
          onSortModelChange={(a, b) => {
            let a_0_field:string = a[0].field;
            if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
            setOrder([a_0_field, a[0].sort].join(' '));
          }}
          page={page}
          setPage={(p) => {setPage(p);}}
        />
      </Paper>
    </Grid>

    {!!activeLocation && <CoachLocationEdit
      onSave={() => {
        let data = new FormData();
        data.append('object', 'location');
        data.append('id', activeLocation.id?.toString() || '');
        data.append('ob[id]', activeLocation.id?.toString() || '');
        if (!!activeLocation.item) {
          data.append(`ob[item]`, activeLocation.item);
        }
        // data.append('ob[extra_data][is_square_logo]', !!extendedPractice.extra_data?.is_square_logo ? '1' : '0');
        // data.append('ob[support_email]', extendedPractice.support_email || '');
        // data.append('ob[language_id]', extendedPractice.language_id?.toString() || '');
        // data.append('ob[intro]', (extendedPractice.full_intro || {})[1]?.toString() || '');
        // data.append('ob[my_practice]', (extendedPractice.full_my_practice || {})[1]?.toString() || '');
        // data.append('ob[description]', (extendedPractice.full_description || {})[1]?.toString() || '');

        mutationSaveAppType.mutate({
          formData: data
        });
      }}
      open={editOpen}
      setOpen={(b) => {
        setActiveLocation(undefined);
        setEditOpen(b);
      }}
      location={activeLocation}
      setLocation={setActiveLocation}
      isLoading={mutationSaveAppType.isPending}
    />}

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    <Fab
      onClick={() => {
        setActiveLocation({
          name: '',
          street: '',
          number: '',
          zip: '',
          city: '',
          practice_types: []
        });
        setEditOpen(true);
      }}
    />
  </Grid>;
}

