import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useEmailTemplate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all_with_count } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  Box,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Fab,
  IconButton,
} from '../../../components/v2/styled';
import { CoachEditEmailTemplate } from '../../../components/v2/dialogs/CoachEditEmailTemplate';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { EmailTemplate } from '../../../models/EmailTemplate';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachGeneralSettingsEmailTemplates: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useEmailTemplate();

  let count = 0;
  let defCount = 0;
  const [page, setPage] = useState<number>(0);
  const [defPage, setDefPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [defOrder, setDefOrder] = useState<string>("id DESC");
  const [search, setSearch] = useState<string>("");

  const [noPractice, setNoPractice] = useState<boolean>(false);

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);

  const [savedOpen, setSavedOpen] = useState<boolean>(false);

  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [readonly, setReadonly] = useState<boolean>(false);
  const [activeEmailTemplate, setActiveEmailTemplate] = useState<EmailTemplate>();

  const {
    data: defaultObjects,
    isLoading: defaultObjectsLoading,
    isError: defaultObjectsError,
    isSuccess: defaultObjectsSuccess,
    refetch: defaultObjectsRefetch,
  } = useQuery({
    queryKey: ["coach", "default_email_templates", (currentPractice?.id || login?.practice_id), {page: defPage, order: defOrder, search: search}],
    queryFn: () =>
      fetch_all_with_count<EmailTemplate>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'email_template',
          fields: [
            'id', 'name', 'type', 'can_be_copied', 'full_subject', 'full_body', 'full_notification_body', 'full_push_body'
          ],
          order: defOrder,
          per_page: 20,
          page: defPage,
          filter: {
            search: search,
            advanced: {
              no_practice: 1
            }
          },
          practice_id: (currentPractice?.id || login?.practice_id)
        },
        login
      ),
    select: (d) => {
      defCount = d.count;
      return d.result;
    },
    enabled: !!(currentPractice?.id || login?.practice_id),
  });
  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "email_templates", (currentPractice?.id || login?.practice_id), {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<EmailTemplate>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'email_template',
          fields: [
            'id', 'name', 'type', 'can_be_copied', 'full_subject', 'full_body', 'full_notification_body', 'full_push_body'
          ],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id)
            }
          },
          practice_id: (currentPractice?.id || login?.practice_id)
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(currentPractice?.id || login?.practice_id),
  });







  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      extra?: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectsRefetch();
      defaultObjectsRefetch();
      setSavedOpen(true);
    },
  });
  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<EmailTemplate>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      objectsRefetch();
      defaultObjectsRefetch();
    },
  });
  const mutationSaveAppType = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<EmailTemplate>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectsRefetch();
      defaultObjectsRefetch();
      setEditOpen(false);
      setSavedOpen(true);
    },
  });








  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'type', headerName: t('coach.models.email_templates.type'), width: 250, sortable: true },
    { field: 'name', headerName: t('coach.models.email_templates.name'), width: 400, sortable: true },
  ];

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Paper sx={{
        padding: 2
      }}>
        <Box sx={{
          marginBottom: 2,
          '& .Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
          }
        }}>
          <Tabs
            value={noPractice ? 1 : 0}
            onChange={(e, v) => {setNoPractice(v === 1);}}
            indicatorColor="primary"
            textColor="primary"
            aria-label="EmailTemplates"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={t("coach.models.email_templates.table_title")} />
            <Tab label={t("coach.settings.email_templates.overview_default")} />
          </Tabs>
          <Divider sx={{borderColor: theme.palette.primary.main}} />
        </Box>

        <DataGrid
          action_count={2}
          hideCount
          actions={(params) => {
            return (!!noPractice ? <>
              <IconButton title="View" color="default" onClick={() => {
                setActiveEmailTemplate(params.row);
                setEditOpen(true);
                setReadonly(true);
              }}>
                <VisibilityIcon />
              </IconButton>
              {!!params.row.can_be_copied && <IconButton title="View" color="default" onClick={() => {
                mutationCustomAction.mutate({formData: {
                  object: 'email_template',
                  class_action: 'custom_api_copy',
                  id: params.row.id,
                  practice_id: (currentPractice?.id || login?.practice_id)
                }});
              }}>
                <ContentCopyIcon />
              </IconButton>}
            </> : <>
              <IconButton title="Edit" color="default" onClick={() => {
                setActiveEmailTemplate(params.row);
                setEditOpen(true);
                setReadonly(false);
              }}>
                <EditIcon />
              </IconButton>
              <IconButton title="Remove" destroyBtn color="default" onClick={() => {
                mutationDestroy.mutate({
                  formData: {
                    object: 'email_template',
                    id: params.row.id,
                    ob: {
                      id: params.row.id,
                    }
                  }
                });
              }}>
                <DeleteIcon />
              </IconButton>
            </>);
          }}
          objectCount={!!noPractice ? defCount : count}
          getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
          objects={(!!noPractice ? (defaultObjects || []) : (objects || [])).map(u => ({
            ...u,
          }))}
          columns={columns}
          loader={!!noPractice ? defaultObjectsLoading : objectsLoading}
          onSortModelChange={(a, b) => {
            let a_0_field:string = a[0].field;
            if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
            setOrder([a_0_field, a[0].sort].join(' '));
          }}
          page={!!noPractice ? defPage : page}
          setPage={(p) => {!!noPractice ? setDefPage(p) : setPage(p);}}
        />
      </Paper>
    </Grid>

    {!!activeEmailTemplate && <CoachEditEmailTemplate
      onSave={() => {
        let trans:{[s:string]: {[n:number]: string}} = {
          'subject': {},
          'body': {},
          'notification_body': {},
          'push_body': {}
        };
        Object.keys(activeEmailTemplate.full_subject || {}).filter(fff => fff.toString() !== '1').forEach(mm => {
          trans['subject'][parseInt(mm, 10)] = (activeEmailTemplate.full_subject || {})[parseInt(mm, 10)];
        })
        Object.keys(activeEmailTemplate.full_body || {}).filter(fff => fff.toString() !== '1').forEach(mm => {
          trans['body'][parseInt(mm, 10)] = (activeEmailTemplate.full_body || {})[parseInt(mm, 10)];
        })
        Object.keys(activeEmailTemplate.full_notification_body || {}).filter(fff => fff.toString() !== '1').forEach(mm => {
          trans['notification_body'][parseInt(mm, 10)] = (activeEmailTemplate.full_notification_body || {})[parseInt(mm, 10)];
        })
        Object.keys(activeEmailTemplate.full_push_body || {}).filter(fff => fff.toString() !== '1').forEach(mm => {
          trans['push_body'][parseInt(mm, 10)] = (activeEmailTemplate.full_push_body || {})[parseInt(mm, 10)];
        })

        mutationSaveAppType.mutate({
          formData: {
            object: 'email_template',
            id: activeEmailTemplate.id,
            fields: ['id'],
            ob: {
              id: activeEmailTemplate.id,
              type: activeEmailTemplate.type,
              name: activeEmailTemplate.name,
              subject: (activeEmailTemplate.full_subject || {})[1] || '',
              body: (activeEmailTemplate.full_body || {})[1] || '',
              notification_body: (activeEmailTemplate.full_notification_body || {})[1] || '',
              push_body: (activeEmailTemplate.full_push_body || {})[1] || ''
            },
            translations: trans
          }
        });
      }}
      open={editOpen}
      setOpen={(b) => {
        setActiveEmailTemplate(undefined);
        setEditOpen(b);
      }}
      readonly={readonly}
      emailTemplate={activeEmailTemplate}
      setEmailTemplate={setActiveEmailTemplate}
      lockedTypes={(objects || []).map(mm => mm.type?.toString() || '')}
    />}

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    <Fab
      onClick={() => {
        setActiveEmailTemplate({
          
        });
        setEditOpen(true);
      }}
    />
  </Grid>;
}

