import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all } from "../../../../lib/v31lib";
import { loginAtom } from '../../../../lib/auth';
import { currentPracticeAtom } from '../../../../lib/practice';

import {
  Box,
  CircularProgress,
  List,
  Paper,
} from '@mui/material';

import {
  Switcher,
  Typography
} from '../../../../components/v2/styled';
import { ListItemLocation } from '../../../../components/v2/list_items/Location';

import { AppointmentType } from '../../../../models/AppointmentType';
import { Location } from '../../../../models/Location';
import { User } from '../../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  noCoach: boolean;
  noLocation: boolean;
  selectedCoach?: User;
  selectedLocation?: Location;
  selectedType: AppointmentType;
  setNoLocation: (d:boolean) => void;
  setSelectedLocation: (d:Location) => void;
}

export const ClientAppointmentNewStepLocation: FunctionComponent<Props> = ({selectedType, selectedCoach, selectedLocation, setSelectedLocation, noCoach, noLocation, setNoLocation}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  // const matchesmd = useMediaQuery(theme.breakpoints.up('md'));

  // const [currentDay, setCurrentDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().toFormat("dd/LL/y"));
  const {
    data: locations,
    // isLoading: locationsLoading,
    // isError: locationsError,
    isSuccess: locationsSuccess,
    // refetch: locationsRefetch,
  } = useQuery({
    queryKey: ["locations", currentPractice?.id],
    queryFn: () =>
      fetch_all<Location>(
        `/nl/v3/pub/fetch_all`,
        {
          object: 'location',
          fields: [
            'id', 'name', 'get_medium', 'street', 'number', 'zip', 'city'
          ],
          page: 0,
          per_page: 1000,
          order: "name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: currentPractice?.id,
              enabled: 1
            }
          }
        },
        login
      ),
    // select: (d) => {
    //   hasVideo = d.filter(ff => ff.is_meeting).length > 0;
    //   hasPhysical = d.filter(ff => !ff.is_meeting).length > 0;
    //   return d;
    // },
    enabled: !!currentPractice?.id,
  });






  
  useEffect(() => {
    if ((locations || []).length == 1) setSelectedLocation((locations || [])[0]);
  }, [locations]);
  
  return <Paper sx={{
    padding: 2
  }}>
    {!locationsSuccess && <Box><CircularProgress /></Box>}
    {!!locationsSuccess && <Box>
      <Switcher
        id="no_loc"
        caption={t("client.appointment.no_location", "Locatie maakt niet uit")}
        backend
        value={noLocation}
        onChange={(c) => {setNoLocation(c);}}
      />

      {!noLocation && <>
        {selectedLocation && <>
          <Typography variant={"subtitle2"}>{t("client.appointment.selected_location")}:</Typography>
          <List>
            <ListItemLocation location={selectedLocation} />
          </List>
        </>}

        {locations.length > 1 && <Typography variant={"subtitle2"}>{t("client.appointment.pick_location")}:</Typography>}
        <List>
          {locations.map(location => (<>
            {(!selectedLocation || selectedLocation.id !== location.id) && <ListItemLocation location={location} onClick={() => {
              setSelectedLocation(location);
            }} />}
          </>))}
        </List>
      </>}
    </Box>}
  </Paper>;
}

