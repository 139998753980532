import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  Box,
  CircularProgress,
  ImageListItem,
} from '@mui/material';

import {
  ImageList,
  Page,
  Typography
} from '../../../components/v2/styled';
import { CardWorkshopBetter } from '../../../components/v2/cards/WorkshopBetter';

import { Workshop } from '../../../models/Workshop';

const { DateTime } = require("luxon");

type Props = {}

export const ClientAppointmentWorkshops: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  // const [currentDay, setCurrentDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().toFormat("dd/LL/y"));

  const {
    data: workshops,
    isLoading: workshopsLoading,
    isError: workshopsError,
    isSuccess: workshopsSuccess,
    refetch: workshopsRefetch,
  } = useQuery({
    queryKey: ["workshops", currentPractice?.id],
    queryFn: () =>
      fetch_all<Workshop>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'workshop',
          fields: [
            'id', 'start_time', 'subject', 'get_medium', 'coach|id/get_medium/full_name', 'end_time', 'location', 'location_name', 'location_street', 'location_number', 'location_zip', 'location_city',
            'location_lat', 'location_lng', 'description', 'max_subscribers', 'max_backups', 'get_subscriber_ids', 'get_backup_ids', 'cost'
          ],
          sub_fields: {
            // 'coach': ['id', 'get_medium', 'coach_skills', 'full_name', 'enabled'],
            'location': ['id', 'get_medium', 'name', 'is_online', 'enabled', 'settings', 'street', 'number', 'zip', 'city'],
            // 'practice': ['id', 'name', 'settings'],
            // 'payments': ['id'],
            // 'appointment_type': ['id', 'colour', 'cost', 'duration_nice', 'name']
          },
          page: 0,
          per_page: 20,
          order: "start_time ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: currentPractice?.id
            }
          }
        },
        login
      ),
      // select: (d) => {
      //   soonCount = d.count;
      //   return d.result;
      // },
    enabled: !!login && !!currentPractice,
  });







  // const mutationDeleteAppointment = useMutation({
  //   mutationFn: (pars: {
  //     formData: any;
  //   }) => {
  //     return fetch_one<{
  //       custom_result: {
  //         success: boolean,
  //         error?: string
  //       }
  //     }>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
  //       pars.formData,
  //       login
  //     );
  //   },
  //   onMutate: (pars: {
  //     formData: any;
  //   }) => {
  //     return true;
  //   },
  //   onError: (data, variables, context) => {
  //     return true;
  //   },
  //   onSuccess: (data, variables, context) => {
  //     setCancelOpen(false);
  //     setCancelAppointment(undefined);
  //     setCancelNote('');
  //     appointmentsSoonRefetch();
  //     appointmentsFutureRefetch();
  //     appointmentsPastRefetch();
  //   },
  // });







  
  // const [open, setOpen] = useState<number>();
  let term = t("client.dashboard.workshops.title", "Workshops").toLowerCase();
  if (!!currentPractice?.settings?.workshops_plural) {
    term = currentPractice.settings.workshops_plural;
  }
  
  // useEffect(() => {
  //   if (!!diaryDay?.weight) setCurrentWeight(diaryDay?.weight);
  // }, [diaryDay]);

  // let inputWeightDisabled = false;
  // if (!!diaryDaySuccess && !!diaryDay.weight) inputWeightDisabled = true;

  return <Page
    title={term}
  >
    {!!workshopsLoading && <Box sx={{textAlign: 'center'}}>
      <CircularProgress />  
    </Box>}
    {!!workshopsSuccess && workshops.length < 1 && <Box sx={{textAlign: 'center'}}>
      <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("general.no_results_found")}</Typography> 
    </Box>}

    {!!workshopsSuccess && workshops.length > 0 && <ImageList cardWidth={400}>
      {workshops.map(workshop => <ImageListItem key={workshop.id} sx={{
        height: "auto !important",
      }}>
        <CardWorkshopBetter
          workshop={workshop}
          refetch={() => {
            workshopsRefetch();
          }}
        />
      </ImageListItem>)}
    </ImageList>}
  </Page>;
}

