import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

// import {
//   Grid,
// } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  Dt,
  Page,
  Typography
} from '../../../components/v2/styled';

import { Export } from '../../../models/Export';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachUserMergeRequests: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  
  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "merge_requests", (currentPractice?.id || login?.practice_id), {page: page, order: order}],
    queryFn: () =>
      fetch_all_with_count<Export>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'merge_request',
          fields: ['id', 'copy', 'original', 'get_status', 'created_at'],
          sub_fields: {
            'copy': ['id', 'full_name', 'email'],
            'original': ['id', 'full_name', 'email']
          },
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: '',
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id)
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(currentPractice?.id || login?.practice_id),
  });







  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'original_full_name', headerName: t('coach.models.merge_requests.original'), width: 450, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem', lineHeight: '52px'}}>{params.row.original?.full_name} ({params.row.original?.email})</Typography>
    </> },
    { field: 'copy_full_name', headerName: t('coach.models.merge_requests.copy'), width: 450, renderCell: params => <>
      <Typography sx={{fontSize: '0.9rem', lineHeight: '52px'}}>{params.row.copy?.full_name} ({params.row.copy?.email})</Typography>
    </> },
    { field: 'get_status', headerName: t('coach.models.merge_requests.status'), width: 100 },
    { field: 'created_at', headerName: t('coach.models.exports.created_at'), width: 200, renderCell: params => <>
      <Dt d={params.row.created_at} f="DDD t" />
    </> },
  ];

  return <Page title={t("coach.menu.merge_requests", "Dubbels")}>
    <DataGrid
      action_count={0}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />
  </Page>;
}
