import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { loginAtom } from '../../../lib/auth';

import {
  Avatar,
  Button as Btn,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
} from '@mui/material';

import MyLocationIcon from '@mui/icons-material/MyLocation';

import {
  Dt,
  Typography
} from '../../../components/v2/styled';
import { CardBase } from './Base';
import { WorkshopDetail } from '../dialogs/WorkshopDetail';

import { Workshop } from '../../../models/Workshop';

type Props = {
  workshop: Workshop;
  refetch: () => void;
};

export const CardWorkshopBetter: FunctionComponent<Props> = ({workshop, refetch}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [open, setOpen] = useState<boolean>(false);

  return (<CardBase>
    {(workshop.get_subscriber_ids || []).indexOf(login?.id || -1) > -1 && <CardHeader
      sx={{
        backgroundColor: theme.palette.primary.main,
        textAlign: 'center'
      }}
      title={t("client.workshops.subscribed")}
    />}
    {(workshop.get_backup_ids || []).indexOf(login?.id || -1) > -1 && <CardHeader
      sx={{
        backgroundColor: theme.palette.primary.main,
        textAlign: 'center'
      }}
      title={t("client.workshops.subscribed_backup")}
    />}
    <CardMedia
      sx={{
        height: 100,
        backgroundPositionY: '40%',
        backgroundColor: (!workshop.get_medium ? 'rgba(0, 0, 0, 0.2)' : 'transparent')
      }}
      image={!!workshop.get_medium ? workshop.get_medium : undefined}
      title={workshop.subject}
    />
    <CardContent style={{marginTop: '-100px', marginBottom: 'auto'}}>
      <Avatar sx={{width: 100, height: 100}} src={workshop.coach_get_medium} />

      <Typography sx={{marginTop: 1, fontWeight: 'bold'}}>{workshop.subject}</Typography>

      {!!workshop.teaser && <Typography sx={{marginTop: 1}}>{workshop.teaser}</Typography>}
      {!!workshop.teaser && <Divider sx={{marginTop: 1}} />}

      <Typography sx={{color: theme.palette.primary.main, fontStyle: 'italic', fontSize: '0.75rem', marginTop: 2}}>{t("coach.models.workshops.start_time")}</Typography>
      <Typography><Dt d={workshop.start_time} f="DD t" /></Typography>
      <Typography sx={{color: theme.palette.primary.main, fontStyle: 'italic', fontSize: '0.75rem', marginTop: 1}}>{t("coach.models.workshops.end_time")}</Typography>
      <Typography><Dt d={workshop.end_time} f="DD t" /></Typography>

      <Typography sx={{color: theme.palette.primary.main, fontStyle: 'italic', fontSize: '0.75rem', marginTop: 2}}>{t("client.workshops.location")} </Typography>
      {!!workshop.location?.id && <>
        <Typography>{workshop.location.name}</Typography>
        <Typography sx={{fontSize: '0.8rem'}}>{[workshop.location.street, workshop.location.number].join(' ')}</Typography>
        <Typography sx={{fontSize: '0.8rem'}}>{[workshop.location.zip, workshop.location.city].join(' ')}</Typography>
      </>}
      {!workshop.location?.id &&<>
        <Typography>{workshop.location_name}</Typography>
        <Typography sx={{fontSize: '0.8rem'}}>{[workshop.location_street, workshop.location_number].join(' ')}</Typography>
        <Typography sx={{fontSize: '0.8rem'}}>{[workshop.location_zip, workshop.location_city].join(' ')}</Typography>
      </>}
      {!!workshop.location_lat && workshop.location_lng && <Btn
        size="small"
        startIcon={<MyLocationIcon />}
        onClick={() => {
          window.open(`https://www.google.com/maps/search/?api=1&query=${workshop.location_lat},${workshop.location_lng}`, "_BLANK");
        }}
        variant="outlined"
        sx={{
          paddingTop: 0.2,
          paddingBottom: 0.2
        }}
      >{t("general.open_in_gmaps")}</Btn>}

      {!!open && <WorkshopDetail
        open={open}
        setOpen={setOpen}
        workshop={workshop}
        refetch={refetch}
      />}
    </CardContent>
    <CardActions>
      <Btn
        onClick={() => {
          setOpen(true);
        }}
      >{t("coach.submenu.agenda.appointments.detail")}</Btn>
    </CardActions>
  </CardBase>);
}