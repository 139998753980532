import React, { ElementType, FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';

import AttachmentIcon from '@mui/icons-material/Attachment';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ReplyIcon from '@mui/icons-material/Reply';

import {
  Box,
  Grid,
} from '@mui/material';

import {
  Dt,
  Typography
} from './styled';

import { NutriMail } from '../../models/NutriMail';

const { DateTime } = require("luxon");

type Props = {
  mail: NutriMail,
  border: boolean;
  onClick?: () => void;
  showTo?: boolean;
};

export const InboxNutriMail: FunctionComponent<Props> = ({mail, border, onClick, showTo}) => {
  const theme = useTheme();
  const [login, setLogin] = useAtom(loginAtom);
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();

  // const [search, setSearch] = useState<string>('');








  // useEffect(() => {
  //   let to = setTimeout(() => {
  //     // refetch
  //   }, 2000);
  
  //   return () => {
  //     clearTimeout(to);
  //   }
  // }, [search]);
  
  return (<>
    <Grid container spacing={0} item xs={12} sx={{
      borderBottom: !!border ? '1px solid rgba(0, 0, 0, 0.2)' : 'none',
      paddingTop: '4px',
      paddingBottom: '4px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)'
      }
    }} onClick={(e) => {
      if (mail.from?.id === login?.id && !mail.sent) {
        navigate("/inbox/new", {state: {mail: mail}});
      } else {
        if (!!onClick) onClick();
      }
    }}>
      <Grid container spacing={0} item xs={12} >

          <Grid item xs={12} md={3}>
            <Box sx={{
              fontSize: '0.9rem',
              '& span': {
                maxWidth: '100%',
                display: 'block'
              },
              ...(mail.from?.id === login?.id || mail.read ? {} : {
                fontWeight: 'bold'
              })
            }}>
              <Typography>
                {mail.replied && <ReplyIcon sx={{
                  display: 'inline-block',
                  marginRight: 1,
                  height: 20,
                  float: 'left'
                }} />}
                {showTo ? mail.to?.full_name : mail.from?.full_name}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={9}>
            <Box sx={{
              display: {
                xs: 'block',
                md: 'flex'
              },
              flex: 10,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              flexWrap: 'nowrap',
              maxWidth: '100%'
            }}>
              <Box sx={{
                width: {
                  xs: '100%',
                  md: `calc(100% - 105px)`
                }
              }}>
                <Box sx={{
                  display: {
                    xs: 'none',
                    md: 'flex'
                  },
                  flex: 10,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  flexWrap: {
                    xs: 'wrap',
                    md: 'nowrap'
                  },
                  width: {
                    xs: '100%',
                    // md: "calc(100% - 40px)"
                  },
                  maxWidth: '100%'
                }}>
                  <Typography noWrap sx={{
                    fontSize: '0.9rem',
                    paddingTop: 0.25
                  }}>
                    <span style={{
                      ...(mail.from?.id === login?.id || mail.read ? {} : {
                        fontWeight: 'bold'
                      })
                    }}>{mail.subject}</span>
                    <FiberManualRecordIcon sx={{
                      fontSize: '0.3rem',
                      margin: '4px 4px 4px 4px',
                      width: 5,
                      color: 'rgba(0, 0, 0, 0.2)',
                      display: {
                        xs: 'none',
                        md: 'inline-block'
                      }
                    }} />
                    <span style={{
                      color: 'rgba(0, 0, 0, 0.4)'
                    }}>{(mail.body || '').replace(/<[^>]*>?/gm, '')}</span>
                  </Typography>
                </Box>
                <Box sx={{
                  display: {
                    xs: 'block',
                    md: 'none'
                  },
                  flex: 10,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  flexWrap: {
                    xs: 'wrap',
                    md: 'nowrap'
                  },
                  width: {
                    xs: '100%',
                    // md: "calc(100% - 40px)"
                  },
                  maxWidth: '100%'
                }}>
                  <Typography noWrap sx={{
                    fontSize: '0.9rem',
                    paddingTop: 0.25,
                    ...(mail.from?.id === login?.id || mail.read ? {} : {
                      fontWeight: 'bold'
                    })
                  }}>{mail.subject}</Typography>
                  <Typography noWrap sx={{
                    fontSize: '0.9rem',
                    paddingTop: 0.25,
                    color: 'rgba(0, 0, 0, 0.4)'
                  }}>{(mail.body || '').replace(/<[^>]*>?/gm, '')}</Typography>
                </Box>
              </Box>

              <Box sx={{
                fontSize: '0.9rem',
                flex: '0 0 50px',
                alignSelf: 'flex-start',
                maxHeight: 40,
                textAlign: 'right',
                [theme.breakpoints.up('md')]: {
                  flex: '0 0 100px',
                  maxHeight: 20,
                },
                display: 'inline-block',
                width: '100%',
                '& span': {
                  maxWidth: '100%',
                  display: 'block'
                }
              }}>
                <Box sx={{
                  display: 'inline-block',
                  fontSize: '0.9rem',
                  width: 38,
                  maxHeight: 20,
                  textAlign: 'right',
                  [theme.breakpoints.up('md')]: {
                    textAlign: 'center'
                  },
                  '& span': {
                    maxWidth: '100%',
                    maxHeight: 20,
                    display: 'block'
                  }
                }}>
                  <AttachmentIcon sx={{
                    height: 20, 
                    marginTop: 0.25,
                    opacity: ((mail.nutri_mail_attachments_count || 0) > 0 ? 1 : 0)
                  }} />
                </Box>

                <Box sx={{
                  width: 60,
                  float: 'right',
                  display: 'inline-block',
                  '& span': {
                    maxWidth: '100%',
                    display: 'block'
                  },
                  ...(mail.from?.id === login?.id || mail.read ? {} : {
                    fontWeight: 'bold'
                  })
                }}>
                  <Typography sx={{
                    fontSize: '0.9rem',
                    marginTop: 0.25
                  }}>
                    {DateTime.now().toFormat("D") === DateTime.fromISO(mail.created_at).toFormat("D") && <Dt d={mail.created_at} f="t" />}
                    {DateTime.now().toFormat("D") !== DateTime.fromISO(mail.created_at).toFormat("D") && <Dt d={mail.created_at} f="d LLL" />}
                  </Typography>
                </Box>
              </Box>

              </Box>
          </Grid>

      </Grid>
    </Grid>
  </>);
}
