import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import { EventInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import nlLocale from '@fullcalendar/core/locales/nl';
import luxonPlugin from '@fullcalendar/luxon3';

import { Tooltip } from 'react-tooltip';

import FilterListIcon from '@mui/icons-material/FilterList';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {
  Box,
  Fab,
} from '@mui/material';

import {
  Page,
  Typography
} from '../../components/v2/styled';
import { CoachAgendaEvent } from '../../components/v2/dialogs/CoachAgendaEvent';
import { CoachAgendaFilter } from '../../components/v2/dialogs/CoachAgendaFilter';
import { CoachDayNote } from '../../components/v2/dialogs/CoachDayNote';

import { Appointment } from '../../models/Appointment';
import { DayNote } from '../../models/DayNote';
import { User } from '../../models/User';

// import HelpersPractice from '../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const CoachAgenda: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const { state } = useLocation();

  const calendarRef = React.useRef<FullCalendar>(null)
  
  const [calStart, setCalStart] = useState<string>((!!state && !!state.day ? DateTime.fromFormat(state.day, "dd/LL/y") : DateTime.now()).startOf("week").toFormat("y-LL-dd TT"));
  const [calEnd, setCalEnd] = useState<string>((!!state && !!state.day ? DateTime.fromFormat(state.day, "dd/LL/y") : DateTime.now()).endOf("week").toFormat("y-LL-dd TT"));
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<{
    coach: number[],
    location: number[],
    appointment_type: number[],
    cancelled: boolean
  }>({
    coach: [],
    location: [],
    appointment_type: [],
    cancelled: false
  });
  const [dayNoteOpen, setDayNoteOpen] = useState<boolean>(false);
  const [dayNote, setDayNote] = useState<DayNote>({});
  const [appointmentOpen, setAppointmentOpen] = useState<boolean>(false);
  const [appointment, setAppointment] = useState<Appointment>();

  // const is_nutri_workshop = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_workshop'});
  // const is_nutri_mail = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_mail'});

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches_fn", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'first_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });

  const {
    data: events,
    isLoading: eventsLoading,
    isError: eventsError,
    isSuccess: eventsSuccess,
    refetch: eventsRefetch,
  } = useQuery({
    queryKey: ["agenda", login?.id, calStart, calEnd, filters.coach.join("c"), filters.location.join("l"), filters.appointment_type.join("a"), filters.cancelled],
    queryFn: () =>
      fetch_one<{custom_result: EventInput[]}>(
        `/nl/v3/objects/custom_action`,
        {
          object: 'appointment',
          class_action: 'custom_api_get_agenda',
          practice_id: (currentPractice?.id || login?.practice_id),
          from: calStart,
          to: calEnd,
          coach_id: filters.coach,
          location_id: filters.location,
          appointment_type_id: filters.appointment_type,
          is_cancelled: !!filters.cancelled ? 1 : 0
        },
        login
      ),
    select: (d) => {
      return d.custom_result;
    },
    enabled: !!(currentPractice?.id || login?.practice_id),
  });






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      daynote?: boolean;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      eventsRefetch();
    },
  });
  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
      daynote?: boolean;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (variables.daynote) {
        eventsRefetch();
        setDayNoteOpen(false);
        setDayNote({});
      }
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      daynote?: boolean;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (variables.daynote) {
        eventsRefetch();
        setDayNoteOpen(false);
        setDayNote({});
      }
    },
  });







  const [earliestHour, setEarliestHour] = useState<number>(9);
  const [latestHour, setLatestHour] = useState<number>(17);

  useEffect(() => {
    let earliest_hour = 9;
    let latest_hour = 22;
    // let latest_hour = 17; // beslist door ingrid op 10 september 2024
    
    if (eventsSuccess && events.length > 0) {
      events.forEach((e) => {
        if (DateTime.fromISO(e.start_time).hour < earliest_hour) earliest_hour = DateTime.fromISO(e.start_time).hour
        if (DateTime.fromISO(e.end_time).hour > latest_hour) latest_hour = DateTime.fromISO(e.end_time).hour;
      });
    }
    // if (overrides) {
    //   Object.values(overrides).forEach((o) => {
    //     Object.values(o.attributes.day_overrides || {}).forEach((d) => {
    //       (d || []).forEach((ddd) => {
    //         if (parseInt(ddd.start_hour, 10) < earliest_hour) earliest_hour = parseInt(ddd.start_hour, 10);
    //         if (parseInt(ddd.end_hour, 10) > latest_hour) latest_hour = parseInt(ddd.end_hour, 10);
    //       });
    //     });
    //   });
    // }

    earliest_hour -= 1;
    latest_hour += 1;
    if (earliest_hour < 0) earliest_hour = 0;
    if (latest_hour > 24) latest_hour = 24;

    setEarliestHour(earliest_hour);
    setLatestHour(latest_hour);

    const myCalendar = calendarRef?.current?.getApi();
    myCalendar?.removeAllEvents();
    myCalendar?.addEventSource((events|| []));
    // setEventsList(events);
  }, [events]);
  useEffect(() => {
    if (!!login?.id) setFilters({
      ...filters,
      coach: [login?.id]
    });
  }, [login]);

  return <Page>
    {/* <Grid container spacing={2}>
      agenda
    </Grid> */}

    <style type="text/css">
      {` .fc .fc-button-primary {
        background-color: ${theme.palette.primary.main} !important;
        border-color: ${theme.palette.primary.main} !important;
        color: ${theme.palette.primary.contrastText} !important;
      } `}
      {` .fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
        background-color: ${theme.palette.primary.dark} !important;
        border-color: ${theme.palette.primary.dark} !important;
        color: ${theme.palette.primary.contrastText} !important;
      } `}
    </style>

    <FullCalendar
      ref={calendarRef}
      // events={this.state.calendarEvents.concat(this.state.background_events).concat(this.state.day_notes).concat(this.state.workshop_events)}
      initialView='timeGridWeek'
      initialDate={calStart}
      initialEvents={(events|| [])}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      }}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, luxonPlugin]}
      locales={[nlLocale]}
      locale={i18n.resolvedLanguage || 'nl'}
      // editable
      eventStartEditable
      weekends
      slotDuration="00:15:00"
      // slotMinutes={15}
      // defaultEventMinutes={30}
      slotMinTime={`${earliestHour < 10 ? 0 : ''}${earliestHour}:00:00`}
      slotMaxTime={`${latestHour < 10 ? 0 : ''}${latestHour}:00:00`}
      slotLabelFormat={{
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'short'
      }}
      height={(document.getElementById("scroll_anchor")?.parentElement?.offsetHeight || 500) - 48}
      scrollTime="08:00:00"
      navLinks
      navLinkDayClick={(date, jsEvent) => {
        // if (moment(date).isAfter(moment().add(1, 'd'), 'd')) {
        //   if (self.state.overrides && self.state.filters.coach && self.state.filters.coach.length === 1) {
        //     let has_override = false;
        //     self.state.overrides.filter((i) => i.attributes.user_id === self.state.filters.coach[0]).forEach((o) => {
        //       if (moment(date).isBetween(moment(o.attributes.start_date).hour(0).minute(0), moment(o.attributes.end_date).hour(23).minute(59), "day", "[]")) has_override = true;
        //     });
        //     if (!has_override) {
        //       self.goto_override(date);
        //     }
        //   } else {
        //     self.goto_override(date);
        //   }
        // }
        let override_ids = (events || []).filter(fff => DateTime.fromISO(fff.start).toFormat("dd/LL/y") === DateTime.fromJSDate(date).toFormat("dd/LL/y")).filter(fff => fff.extendedProps?.override);
        if (override_ids.length > 0 && !!override_ids[0].extendedProps?.override) {
          navigate(`/agenda_override/${override_ids[0].extendedProps?.override}`);
        } else {
          navigate(`/agenda_override/new`, {state: {time: DateTime.fromJSDate(date).toFormat("dd/LL/y"), user_id: filters.coach.length === 1 ? filters.coach[0] : undefined}});
        }
      }}
      dayHeaderContent={(arg) => {
        let override_ids = (events || []).filter(fff => DateTime.fromISO(fff.start).toFormat("dd/LL/y") === DateTime.fromJSDate(arg.date).toFormat("dd/LL/y")).filter(fff => fff.extendedProps?.override);
        if (override_ids.length > 0) {
          return <>{arg.text} <WarningAmberIcon color="warning" sx={{marginBottom: -0.5, height: '0.8em'}} /></>
        } else {
          return arg.text;
        }
      }}
      datesSet={(arg) => {
        // console.log(arg);
        setCalStart(DateTime.fromJSDate(arg.start).toFormat("y-LL-dd TT"));
        setCalEnd(DateTime.fromJSDate(arg.end).toFormat("y-LL-dd TT"));
      }}
      eventDidMount={(arg) => {
        let tt = '';

        if (arg.event.extendedProps.popup_data) {
          tt = arg.event.extendedProps.popup_data;
        } else {
          let lines = [];
          lines.push([DateTime.fromISO(arg.event.startStr).toFormat("t"), DateTime.fromISO(arg.event.endStr).toFormat("t")].join(" - "));
          if (arg.event.extendedProps.note) lines.push(arg.event.extendedProps.note);
          if (arg.event.extendedProps.type) lines.push(arg.event.extendedProps.type.label);
          if (arg.event.extendedProps.coach) lines.push(arg.event.extendedProps.coach.label);
          if (arg.event.extendedProps.location) lines.push(arg.event.extendedProps.location.label);
          if (arg.event.extendedProps.client) lines.push(arg.event.extendedProps.location.client.label);
          if (arg.event.extendedProps.client_input) lines.push(arg.event.extendedProps.location.client_input);
          tt = lines.join("<br>")
        }

        arg.el.setAttribute("data-tooltip-id", "event_tt");
        arg.el.setAttribute("data-tooltip-html", tt);
      }}
      selectable
      dateClick={(arg) => {
        if (arg.allDay) {
          setDayNote({
            date: DateTime.fromJSDate(arg.date).toFormat("dd/LL/y"),
            user_id: filters.coach.length === 1 ? filters.coach[0] : undefined
          });
          setDayNoteOpen(true);
        } else {
          let app = (events || []).filter(fff => !fff.extendedProps?.is_workshop && !fff.extendedProps?.id && DateTime.fromISO(fff.start) <= DateTime.fromJSDate(arg.date) && DateTime.fromISO(fff.end) >= DateTime.fromJSDate(arg.date))[0];

          setAppointment({
            start_time: DateTime.fromJSDate(arg.date).toFormat("dd/LL/y t"),
            end_time: DateTime.fromJSDate(arg.date).plus({minutes: 30}).toFormat("dd/LL/y t"),
            coach_id: (filters.coach.length < 1 ? login?.id : (filters.coach.length === 1 ? filters.coach[0] : undefined)),
            location_id: (!!app ? app.extendedProps?.location?.value : undefined),
            appointment_type_id: 1
          });
          setAppointmentOpen(true);
        }
      }}
      eventClick={(arg) => {
        // console.log(arg);

        if (arg.event.allDay) {
          setDayNote({
            id: arg.event.extendedProps.id,
            note: arg.event.extendedProps.note,
            date: DateTime.fromFormat(arg.event.extendedProps.time, "y-LL-dd").toFormat("dd/LL/y"),
            user_id: arg.event.extendedProps.coach.value
          });
          setDayNoteOpen(true);
        } else {
          // check workshop or bg
          if (
            !arg.event.extendedProps.is_workshop && 
            (!login?.is_secretary || (!!login.is_secretary && !!arg.event.extendedProps.location)) &&
            !!arg.event.extendedProps.coach
          ) {
            console.log('ev', arg.event);
            setAppointment({
              ...arg.event.extendedProps,
              id: arg.event.extendedProps.id,
              start_time: DateTime.fromJSDate(arg.event.start).toFormat("dd/LL/y t"),
              end_time:  DateTime.fromJSDate(arg.event.end).toFormat("dd/LL/y t"),
              appointment_type_id: arg.event.extendedProps.type?.value,
              location_id: arg.event.extendedProps.location?.value,
              coach_id: arg.event.extendedProps.coach?.value,
              user_id: arg.event.extendedProps.client?.value,
              email: arg.event.extendedProps.client_email,
              comment_coach: arg.event.extendedProps.note,
              comment_yellow: arg.event.extendedProps.yellow_note,
              get_event_object: arg.event.extendedProps,
              recurrence_id: arg.event.extendedProps.recurrence_id
            });
            setAppointmentOpen(true);
          }
        }
      }}
      eventDrop={(arg) => {
        // mutate
        mutationCustomAction.mutate({
          formData: {
            object: 'appointment',
            class_action: 'custom_api_set_new_date',
            id: arg.event.extendedProps.id,
            start_time: DateTime.fromJSDate(arg.event.start).toFormat("dd/LL/y t")
          }
        });
      }}
      // eventResize={this.handleEventResize}
      // eventPositioned={this.handleEventPositioned}
    />

    <Tooltip id="event_tt" style={{zIndex: 1200}} />

    {/* {(this.state.plan_for_client || more_than_1_coach_filter || this.state.diff_tz) && <Box sx={{ */}
    {(filters.coach.length !== 1 || (filters.coach.length === 1 && filters.coach[0] !== login?.id)) && <Box sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.warning.main,
      width: '100%',
      padding: 0.5,
      zIndex: 3,
      textAlign: 'center'
    }} id="filter_warning">

      {/* {this.state.diff_tz && <React.Fragment>
        <Typography variant="caption" component="p">{t("general_timezone_warning", "Let op, de getoonde tijdstippen ({tz_location}) zijn verschillend van uw tijdszone ({tz_user})").replace("{tz_location}", "Europe/Brussels").replace("{tz_user}", this.state.tz)}</Typography>
      </React.Fragment>} */}

      {(filters.coach.length !== 1 || (filters.coach.length === 1 && filters.coach[0] !== login?.id)) && <Typography
        variant="caption"
      >{t("coach.submenu.agenda.filter_warning")} ({(coaches || []).filter(fff => filters.coach.indexOf(fff.id || 0) > -1).map(coach => coach.first_name).join(", ")})</Typography>}

      {/* {this.state.plan_for_client && <React.Fragment>
        <Typography variant="caption" component="p">{t("coach.submenu.agenda.plan_for_client_warning", "Je bent een afspraak aan het inplannen voor")} {this.state.plan_for_client.attributes.first_name} {this.state.plan_for_client.attributes.last_name} ({this.state.plan_for_client.attributes.email}) <Button style={{fontSize: '0.7rem', marginLeft: 10}} size="small" variant="outlined" onClick={(e) => {e.preventDefault(); this.setState({plan_for_client: undefined});}}>{t("coach.submenu.agenda.plan_for_client_cancel", "Stoppen")}</Button></Typography>
      </React.Fragment>} */}
    </Box>}

    <CoachAgendaFilter
      open={filterOpen}
      setOpen={setFilterOpen}
      filters={filters}
      setFilters={setFilters}
    />

    {!!appointment && <CoachAgendaEvent
      open={appointmentOpen}
      setOpen={setAppointmentOpen}
      appointment={appointment}
      setAppointment={setAppointment}
      checkVacation={(d) => {
        let v = false;

        if (filters.coach.length === 1) {
          // this.state.overrides.filter((i) => i.attributes.user_id === this.state.filters.coach[0]).forEach((o) => {
          //   if (o.attributes.is_vacation) {
          //     if (moment(d).isBetween(moment(o.attributes.start_date).hour(0).minute(0), moment(o.attributes.end_date).hour(23).minute(59))) is_vacation = true;
          //   }
          // });
        }

        return v;
      }}
      checkOverlap={(app_id?: number, coach_id?:number, start_time?: string, end_time?: string) => {
        let overlap = false;

        if (!!coach_id) {
          if (!!start_time && !!end_time) {
            (events || []).forEach((ev) => {
              let start = DateTime.fromFormat(start_time, "dd/LL/y t");
              let end = DateTime.fromFormat(end_time, "dd/LL/y t");
              // if (this.state.dialog_fields.type) {
              //   end = moment(this.state.dialog_fields.time).add(this.state.apptype_stats[this.state.dialog_fields.type.value].duration, 'm')
              // }
    
// console.log(start.format("HH:mm"));
// console.log(end.format("HH:mm"));
// console.log(moment(a.start).format("HH:mm"));
// console.log(moment(a.end).format("HH:mm"));
// console.log(start.isBetween(moment(a.start), moment(a.end), 'm', '[['));
// console.log(end.isBetween(moment(a.start), moment(a.end), 'm', ']]'));
    // console.log(DateTime.fromISO(ev.start).toFormat("dd/LL/y t"), DateTime.fromISO(ev.end).toFormat("dd/LL/y t"), start.toFormat("dd/LL/y t"), end.toFormat("dd/LL/y t"));
              if (
                !ev.extendedProps?.is_workshop &&
                !!ev.extendedProps?.coach_id &&
                ev.extendedProps?.id?.toString() !== (app_id || "").toString() &&
                ev.extendedProps?.coach_id?.toString() === coach_id.toString() &&
                ((DateTime.fromISO(ev.start) <= start && DateTime.fromISO(ev.end) >= start) || (DateTime.fromISO(ev.start) <= end && DateTime.fromISO(ev.end) >= end))
              ) {
                overlap = true;
              }
            });
          }
        }

        return overlap;
      }}
      refetch={() => {
        eventsRefetch();
      }}
    />}

    <CoachDayNote
      open={dayNoteOpen}
      setOpen={setDayNoteOpen}
      dayNote={dayNote}
      setDayNote={setDayNote}
      onDestroy={() => {
        mutationDestroy.mutate({
          formData: {
            object: 'day_note',
            id: dayNote.id,
            ob: {
              id: dayNote.id,
            }
          },
          daynote: true
        });
      }}
      onClick={() => {
        mutationSave.mutate({
          formData: {
            object: 'day_note',
            fields: ['id'],
            id: dayNote.id,
            ob: {
              ...dayNote,
              practice_id: (currentPractice?.id || login?.practice_id)
            }
          },
          daynote: true
        });
      }}
    />

    <Fab
      aria-label={t("coach.submenu.agenda.filters")}
      sx={{
        position: 'absolute',
        bottom: 16,
        right: 16,
        zIndex: 1201
      }}
      color="primary"
      onClick={() => {
        setFilterOpen(true);
      }}
    >
      <FilterListIcon />
    </Fab>
  </Page>;
}
