import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HikingIcon from '@mui/icons-material/Hiking';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ViewDayIcon from '@mui/icons-material/ViewDay';

import {
  Box,
  Button as Btn,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Page,
  Typography
} from '../../components/v2/styled';
import { EatMoment } from '../../components/v2/balls/EatMoment';
import { EatMomentOverview } from '../../components/v2/dialogs/EatMomentOverview';
import { MoveMomentOverview } from '../../components/v2/dialogs/MoveMomentOverview';
import { MoveMoment } from '../../components/v2/balls/MoveMoment';
import { EatDayOverview } from '../../components/v2/dialogs/EatDayOverview';
import { MoveDayOverview } from '../../components/v2/dialogs/MoveDayOverview';

import { DiaryDay } from '../../models/DiaryDay';
import { EatMoment as EatMmnt } from '../../models/EatMoment';
import { MoveMoment as MoveMmnt } from '../../models/MoveMoment';

const { DateTime } = require("luxon");

type Props = {}

export const ClientDiaryWeek: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const { state } = useLocation();

  const [currentDay, setCurrentDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().toFormat("dd/LL/y"));

  const {
    data: diaryDays,
    isLoading: diaryDaysLoading,
    isError: diaryDaysError,
    isSuccess: diaryDaysSuccess,
    refetch: diaryDaysRefetch,
  } = useQuery({
    queryKey: ["diary", "diary_week", login?.id, currentDay],
    queryFn: () =>
      fetch_all<DiaryDay>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'diary_day',
          fields: [
            'id', 'day', 'wake_time', 'sleep_time', 'move_moment_list', 'breakfast', 'lunch', 'diner', 'snack1', 'snack2', 'snack3', 'weight', 'given_wake', 'given_sleep', 'feedbacks'
          ],
          per_page: 7,
          page: 0,
          filter: {
            advanced: {
              day: currentDay,
              week: 1,
              user_id: login?.id
            }
          }
        },
        login
      ),
    select: (d) => {
      return d.reverse();
    },
    enabled: !!login && !!currentDay,
  });








  const [eatDayOverviewDay, setEatDayOverviewDay] = useState<DiaryDay>();
  const [eatDayOverviewOpen, setEatDayOverviewOpen] = useState<boolean>(false);

  const [moveDayOverviewDay, setMoveDayOverviewDay] = useState<DiaryDay>();
  const [moveDayOverviewOpen, setMoveDayOverviewOpen] = useState<boolean>(false);
  
  const [moveOpen, setMoveOpen] = useState<boolean>(false);
  const [moveMoment, setMoveMoment] = useState<MoveMmnt>();
  
  const [eatOpen, setEatOpen] = useState<boolean>(false);
  const [eatMoment, setEatMoment] = useState<EatMmnt>();

  // useEffect(() => {
  //   // setNewWeight(diaryDay?.weight || newWeight || 0);
  // }, [diaryDays]);

  return <Page>
    <Grid container spacing={1}>
      <Grid item xs={6} sx={{
        textAlign: 'left'
      }}>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            setCurrentDay(DateTime.fromFormat(currentDay, "dd/LL/y").minus({days: 7}).toFormat("dd/LL/y"));
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <DatePicker
          // placeholder="DD/MM/YY"
          // autoOk={true}
          disableFuture={false}
          openTo="year"
          label=""
          format={"dd/LL/y"}
          views={["year", "month", "day"]}
          setValue={(e) => {
            setCurrentDay(e);
            // this.setState({day: e}, () => {
            //   this.fetch_day();
            // });
          }}
          value={currentDay}
          id="option_date"
          sx={{
            box: {
              width: 'auto',
              display: 'inline-block'
            },
            field_box: {
              "svg": {
                display: 'none'
              }
            },
            field: {
              width: 'auto',
              marginTop: 0,
              "input": {
                textAlign: 'center'
              }
            }
          }}
        />
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            setCurrentDay(DateTime.fromFormat(currentDay, "dd/LL/y").plus({days: 7}).toFormat("dd/LL/y"));
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Grid>
      <Grid item xs={6} sx={{
        textAlign: 'right'
      }}>
        <Button
          fullwidth={false}
          onClick={(e) => {
            e.preventDefault();
            navigate("/diary", {state: {day: currentDay}});
          }}
          sx={{marginTop: 1}}
          id="goto_week_overview"
          label={t("client.diary.eat_diary.day_overview_action", "Dagoverzicht")}
          startIcon={<ViewDayIcon />}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {diaryDaysLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
        <CircularProgress />
      </Grid>}
      {diaryDaysSuccess && [0, 1, 2, 3, 4, 5, 6].map(i => {
        let date = DateTime.fromFormat(currentDay, "dd/LL/y").plus({day: i})
        let day = (diaryDays || []).filter(ff => DateTime.fromISO(ff.day).toFormat("dd/LL/y") === date.toFormat("dd/LL/y"))[0];

        let total_steps = 0;
        let total_km = 0.0;
        if (day && (day.move_moment_list || []).length > 0) {
          (day.move_moment_list || []).forEach(m => {
            if (parseInt(m.amount_steps, 10) > 0) total_steps += parseInt(m.amount_steps, 10);
            if (parseFloat(m.amount_km) > 0) total_km += parseFloat(m.amount_km);
          });
        }

        return <>
          <Grid container item xs={12} sx={{marginTop: 2}}>
            <Grid container item xs={12} spacing={2}>
              <Grid item md={2} xs={4} style={{paddingTop: 0, paddingBottom: 0, padding: 1}}>
                <Typography variant="h5" sx={{fontSize: "1.05rem"}}>{date.toFormat("cccc")}</Typography>
                <Typography variant="h5" sx={{fontSize: "1.25rem"}}>{date.toFormat("dd LLL")}</Typography>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/diary", {state: {day: date.toFormat("dd/LL/y")}})
                  }}
                  size="small"
                  sx={{padding: 0, minWidth: 30}}
                >
                  <MenuBookIcon />
                </IconButton>
                {!!day && (!!day.breakfast || !!day.snack1 || !!day.lunch || !!day.snack2 || !!day.diner || !!day.snack3) && <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    setEatDayOverviewDay(day);
                    setEatDayOverviewOpen(true);
                  }}
                  size="small"
                  sx={{padding: 0, minWidth: 30}}
                >
                  <RestaurantIcon />
                </IconButton>}
                {!!day && (day.move_moment_list || []).length > 0 && <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    setMoveDayOverviewDay(day);
                    setMoveDayOverviewOpen(true);
                  }}
                  size="small"
                  sx={{padding: 0, minWidth: 30}}
                >
                  <HikingIcon />
                </IconButton>}
              </Grid>
              {!day && <Grid item md={10} xs={8} sx={{paddingTop: 0, paddingBottom: 0, fontStyle: 'italic'}}>{t("coach.diary.no_data", "Geen data beschikbaar voor deze dag")}</Grid>}
              {!!day && <>
                <Grid item md={4} xs={8} style={{paddingTop: 0, paddingBottom: 0}}>
                  <Grid container spacing={0}>
                    {(day.move_moment_list || []).length > 0 && <Box sx={{marginLeft: -1, width: '100%'}}>
                      {(day.move_moment_list || []).map(mmoment => <MoveMoment mmoment={mmoment} onClick={() => {
                        setMoveMoment(mmoment);
                        setMoveOpen(true);
                      }} />)}  
                    </Box>}

                    {(!!day.breakfast || !!day.snack1 || !!day.lunch || !!day.snack2 || !!day.diner || !!day.snack3) && <Box sx={{marginLeft: -1, width: '100%'}}>
                      {!!day.breakfast && <EatMoment emoment={day.breakfast} onClick={() => {
                        setEatMoment(day.breakfast);
                        setEatOpen(true);
                      }} icon={<>1</>} />}
                      {!!day.snack1 && <EatMoment emoment={day.snack1} onClick={() => {
                        setEatMoment(day.snack1);
                        setEatOpen(true);
                      }} icon={<>2</>} />}
                      {!!day.lunch && <EatMoment emoment={day.lunch} onClick={() => {
                        setEatMoment(day.lunch);
                        setEatOpen(true);
                      }} icon={<>3</>} />}
                      {!!day.snack2 && <EatMoment emoment={day.snack2} onClick={() => {
                        setEatMoment(day.snack2);
                        setEatOpen(true);
                      }} icon={<>4</>} />}
                      {!!day.diner && <EatMoment emoment={day.diner} onClick={() => {
                        setEatMoment(day.diner);
                        setEatOpen(true);
                      }} icon={<>5</>} />}
                      {!!day.snack3 && <EatMoment emoment={day.snack3} onClick={() => {
                        setEatMoment(day.snack3);
                        setEatOpen(true);
                      }} icon={<>6</>} />}
                    </Box>}
                  </Grid>
                </Grid>
                <Grid item md={2} xs={4} sx={{paddingTop: 0, paddingBottom: 0, textlign: 'center'}}>
                  {total_steps > 0 && <Typography variant="h5" sx={{fontSize: "0.9rem"}}>{t("coach.diary.amount_steps", "Aantal stappen")}</Typography>}
                  {total_steps > 0 && <Typography variant="h5" sx={{fontSize: "1.2rem"}}>{total_steps}</Typography>}
                  {total_km > 0 && <Typography variant="h5" sx={{fontSize: "0.9rem", marginTop: 1}}>{t("coach.diary.amount_km", "Aantal km")}</Typography>}
                  {total_km > 0 && <Typography variant="h5" sx={{fontSize: "1.2rem"}}>{total_km.toLocaleString(undefined, {maximumFractionDigits: 1})} km</Typography>}
                </Grid>
                <Grid item md={2} xs={4} sx={{paddingTop: 0, paddingBottom: 0, textlign: 'center'}}>
                  {day.sleep_time && <Typography variant="h5" sx={{fontSize: "0.9rem"}}>{t("coach.diary.sleep_at", "Gaan slapen om")}</Typography>}
                  {day.sleep_time && <Typography variant="h5" sx={{fontSize: "1.2rem"}}>{day.given_sleep}</Typography>}
                  {day.wake_time && <Typography variant="h5" sx={{fontSize: "0.9rem", marginTop: 1}}>{t("coach.diary.wake_at", "Wakker om")}</Typography>}
                  {day.wake_time && <Typography variant="h5" sx={{fontSize: "1.2rem"}}>{day.given_wake}</Typography>}
                </Grid>
                <Grid item md={2} xs={4} sx={{paddingTop: 0, paddingBottom: 0, textlign: 'center'}}>
                  {day.weight && <Typography variant="h5" sx={{fontSize: "1.5rem", marginTop: 2}}>{day.weight.toLocaleString(undefined, {maximumFractionDigits: 1})}kg</Typography>}
                </Grid>
              </>}
            </Grid>
          </Grid>
          {i < 6 && <Grid item xs={12}><Divider /></Grid>}
        </>;
      })}
      

    </Grid>

    {!!eatMoment && <EatMomentOverview
      open={eatOpen}
      setOpen={setEatOpen}
      eatMoment={eatMoment}
      refetch={() => {
        diaryDaysRefetch();
      }}
    />}
    {!!moveMoment && <MoveMomentOverview
      open={moveOpen}
      setOpen={setMoveOpen}
      moveMoment={moveMoment}
      refetch={() => {
        diaryDaysRefetch();
      }}
    />}
    {!!eatDayOverviewDay && !!login && <EatDayOverview
      open={eatDayOverviewOpen}
      setOpen={setEatDayOverviewOpen}
      diaryDay={eatDayOverviewDay}
      refetch={() => {
        diaryDaysRefetch();
      }}
      user={login}
    />}
    {!!moveDayOverviewDay && !!login && <MoveDayOverview
      open={moveDayOverviewOpen}
      setOpen={setMoveDayOverviewOpen}
      diaryDay={moveDayOverviewDay}
      refetch={() => {
        diaryDaysRefetch();
      }}
      user={login}
    />}
  </Page>;
}

