import React, { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';
import { setCookie } from 'react-use-cookie';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';

import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from '@mui/material';

import {
  Button,
  ColourPicker,
  Editor,
  FileUpload,
  ImagePicker,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { CardCoach } from '../../../components/v2/cards/Coach';
import { Confirm } from '../../../components/v2/dialogs/Confirm';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { CoachSkill } from '../../../models/CoachSkill';
import { User } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachSettingsAccount: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [extendedUser, setExtendedUser] = useState<User>();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [removeOpen, setRemoveOpen] = useState<boolean>(false);
  const [flickerEditor, setFlickerEditor] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);

  const [activeId, setActiveId] = useState<number>(login?.id || 0);
  const [bgItem, setBgItem] = useState<File>();
  const [bgItemUrl, setBgItemUrl] = useState<string>();

  const fields = [
    'id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'get_item_thumb', 'get_item', 'type', 'settings',
    'practice|id/name/item_is_square/get_medium', 'calendar_url', 'birth_date', 'get_bg_medium', 'profile_text', 'is_background_colour',
    'gender', 'street', 'number', 'zip', 'city', 'country_id', 'phone_mobile', 'phone_fixed', 'coach_skills', 'background_colour', 'ehealth',
    'get_medium', 'is_super'
  ];

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: coachSkills,
    isLoading: coachSkillsLoading,
    isError: coachSkillsError,
    isSuccess: coachSkillsSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coach_skills"],
    queryFn: () =>
      fetch_all<CoachSkill>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'coach_skill',
          fields: ['id', 'name'],
          order: 'name ASC'
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: extendedUserTemp,
    isLoading: extendedUserLoading,
    isError: extendedUserError,
    isSuccess: extendedUserSuccess,
    refetch: aextendedUserRefetch,
  } = useQuery({
    queryKey: ["settings", activeId],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: fields,
          id: activeId
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!activeId,
  });








  // const mutationCheckEmail = useMutation({
  //   mutationFn: (pars: {
  //     formData: any;
  //   }) => {
  //     return fetch_one<{exists: boolean}>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/pub/check_email`,
  //       pars.formData,
  //       undefined
  //     );
  //   },
  //   onMutate: (pars: {
  //     formData: any;
  //   }) => {
  //     // setIsCheckEmailError(false);
  //     // setCheckEmailError(undefined);
  //     return true;
  //   },
  //   onError: (data, variables, context) => {
  //     // setIsCheckEmailError(true);
  //     // setCheckEmailError(data as any);
  //     return true;
  //   },
  //   onSuccess: (data, variables, context) => {
  //     setEmailError(!!data.exists);
  //   },
  // });
  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      extra?: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      // if (!!refetch) refetch();
      setCookie('nutriportal_token', '');
      setCookie('nutriportal_email', '');
      navigate("/");
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      aextendedUserRefetch();
      setSavedOpen(true);
      coachesRefetch();
      if (activeId === login?.id) setLogin(data);
    },
  });








  const saveUser = () => {
    if (!!extendedUser) {
      let data = new FormData();
      data.append('object', 'user');
      data.append('id', extendedUser.id?.toString() || '');
      data.append('ob[id]', extendedUser.id?.toString() || '');
      data.append('ob[first_name]', extendedUser.first_name || '');
      data.append('ob[last_name]', extendedUser.last_name || '');
      if (!!extendedUser.item) {
        data.append(`ob[item]`, extendedUser.item);
      }
      data.append('ob[is_background_colour]', !!extendedUser.is_background_colour ? '1' : '0');
      data.append('ob[background_colour]', extendedUser.background_colour || '');
      data.append('ob[profile_text]', extendedUser.profile_text || '');
      (extendedUser.coach_skills || []).forEach(skill => {
        data.append('ob[coach_skill_ids][]', skill.id.toString());
      });
      if (!!bgItem) {
        data.append(`ob[background_image]`, bgItem);
      }
      if (!!bgItemUrl) {
        data.append(`ob[set_background_image_url]`, bgItemUrl);
      }
      
      fields.forEach((word, index) => {
        data.append(`fields[]`, word);
      });
      // ['id', 'full_name'].forEach((word, index) => {
      //   data.append(`sub_fields[to][]`, word);
      // });
      
      mutationSave.mutate({
        formData: data
      });
    }
  }

  useEffect(() => {
    if (!!extendedUserTemp) {
      setExtendedUser(extendedUserTemp);
    }
  }, [extendedUserTemp]);
  // useEffect(() => {
  //   let to = setTimeout(() => {
  //     if (!!extendedUser && !!extendedUser.email) {
  //       mutationCheckEmail.mutate({formData: {
  //         email: extendedUser.email,
  //         skip: login?.id
  //       }});
  //     }
  //   }, 2000);

  //   return () => {
  //     clearTimeout(to);
  //   }
  // }, [!!extendedUser, extendedUser?.email]);

  return <Grid container spacing={2}>
    {!!extendedUserLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
      <CircularProgress />
    </Grid>}
    {!!extendedUser && <>
      {login?.is_super && (coaches || []).length > 1 && <Grid item xs={12}>
        {(coaches || []).map(coach => <Button
          label={coach.full_name || ''}
          id={`coach_${coach.id}`}
          onClick={() => {
            setFlickerEditor(true);
            setActiveId(coach.id || 0);
            setTimeout(() => {setFlickerEditor(false);}, 200);
          }}
          contained={activeId === coach.id}
          sx={{
            marginTop: 0,
            marginRight: 1
          }}
        />)}
      </Grid>}
      
      <Grid item xs={12} md={4} container spacing={2} sx={{alignContent: 'baseline'}}>

        {/* <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Box sx={{
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              display: "flex",
              flex: 1
            }}>
              <Avatar sx={{
                height: 120,
                width: 120
              }} src={extendedUser.get_item_thumb}></Avatar>
              <Typography sx={{
                marginTop: 2,
                fontSize: "1.5em"
              }}>{extendedUser.first_name} {extendedUser.last_name}</Typography>
            </Box>
          </Paper>
        </Grid> */}

        <Grid item xs={12}>
          <CardCoach
            coach={{
              ...extendedUser,
              settings: {
                ...(extendedUser.settings || {}),
                is_background_colour: extendedUser.is_background_colour,
                background_colour: extendedUser.background_colour
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Box sx={{
              textAlign: "justify"
            }}>
              <Typography>{t("coach.submenu.account_settings.password_change_description")}</Typography>
            </Box>
            <Box sx={{
              marginTop: 1,
              textAlign: "center"
            }}>
              <Button
                id="pass_reset"
                contained
                onClick={(e) => {
                  setConfirmOpen(true);
                  // if (window.confirm(t("general.confirm.password_reset", "Bent u zeker dat u dit item wil verwijderen?"))) {
                  //   // this.do_pass_reset(e);
                  // }
                }}
                label={t("coach.general.buttons.reset_password", "Wachtwoord wijzigen")}
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Box sx={{
              textAlign: "justify"
            }}>
              <Typography>{t("coach.submenu.account_settings.close_account_description")}</Typography>
            </Box>
            <Box sx={{
              marginTop: 1,
              textAlign: "center"
            }}>
              <Button
                id="close_acc"
                contained
                secondary
                onClick={(e) => {
                  setRemoveOpen(true);
                }}
                label={t("coach.general.buttons.close_account", "Account sluiten")}
              />
            </Box>
          </Paper>
        </Grid>

      </Grid>

      <Grid container item xs={12} md={8} sx={{alignContent: 'baseline'}}>
        <Grid item xs={12}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("coach.submenu.account_settings.profile")}</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="first_name"
                  caption={t("client.models.users.first_name", "Voornaam")}
                  placeholder={t("client.models.users.placeholders.first_name", "Vul hier je voornaam in")}
                  value={extendedUser.first_name || ''}
                  onChange={(e) => {
                    setExtendedUser({
                      ...extendedUser,
                      first_name: e
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="last_name"
                  caption={t("client.models.users.last_name", "Achternaam")}
                  placeholder={t("client.models.users.placeholders.last_name", "Vul hier je achternaam in")}
                  value={extendedUser.last_name || ''}
                  onChange={(e) => {
                    setExtendedUser({
                      ...extendedUser,
                      last_name: e
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  caption={t("client.models.users.item", "Afbeelding")}
                  fileInputName='item'
                  selected={extendedUser.item}
                  onChange={(f) => {
                    setExtendedUser({
                      ...extendedUser,
                      item: f as File
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!!mutationSave.isPending && <CircularProgress />}
                {!mutationSave.isPending && <Button
                  id="save_profile"
                  disabled={!extendedUser.first_name || !extendedUser.last_name}
                  contained
                  onClick={(e) => {
                    saveUser();
                  }}
                  label={t("client.general.buttons.submit", "Opslaan")}
                  sx={{
                    marginTop: 1
                  }}
                />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sx={{marginTop: 2}}>
          <Paper sx={{
            padding: 2
          }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">{t("coach.submenu.account_settings.theming", "Publieke voorstelling")}</Typography>
                <Divider />
              </Grid>
              <Grid item xs={12} md={6}>
                <Switcher
                  id="is_bg_colour"
                  caption={t("coach.models.coaches.is_background_colour", "Gebruik achtergrondkleur ipv achtergrondafbeelding")}
                  value={!!extendedUser?.is_background_colour}
                  onChange={(c) => {
                    setExtendedUser({
                      ...extendedUser,
                      is_background_colour: c
                    });
                  }}
                />
              </Grid>
              {!!extendedUser?.is_background_colour && <Grid item xs={12} md={6}>
                <ColourPicker
                  label={t("coach.models.coaches.background_colour", "Achtergrondkleur")}
                  value={extendedUser?.background_colour || 'rgba(0, 0, 0, 0.4)'}
                  onChange={(v) => {
                    setExtendedUser({
                      ...extendedUser,
                      background_colour: v
                    });
                  }}
                />
              </Grid>}
              {!extendedUser?.is_background_colour && <Grid item xs={12} md={6}>
                <Typography sx={{fontSize: '0.8rem'}}>{t("coach.models.coaches.background_image", "Achtergrondafbeelding")}</Typography>
                <ImagePicker
                  existing={extendedUser?.get_bg_medium}
                  item={bgItem}
                  itemUrl={bgItemUrl}
                  onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
                    setBgItem(_item);
                    setBgItemUrl(_itemUrl);
                  }}
                />
              </Grid>}
              <Grid item xs={12}>
                <Typography sx={{fontSize: '0.8rem'}}>{t("coach.models.coaches.profile_text", "Korte profieltekst voor 'Mijn Praktijk'")}:</Typography>
                {!flickerEditor && <Editor
                  advanced
                  label=""
                  value={extendedUser.profile_text || ''}
                  onChange={(e) => {
                    setExtendedUser({
                      ...extendedUser,
                      profile_text: e
                    });
                  }}
                />}
              </Grid>
              {!extendedUser.is_secretary && <Grid item xs={12}>
                <Typography sx={{fontSize: '0.7rem'}}>{t("coach.submenu.account_settings.skills")}:</Typography>
                {(coachSkills || []).map(skill => <Chip
                  sx={{marginRight: 1, marginBottom: 1, cursor: 'pointer'}}
                  label={skill.name}
                  onClick={(e) => {
                    if (extendedUser.coach_skills.filter(fff => fff.id === skill.id).length > 0) {
                      setExtendedUser({
                        ...extendedUser,
                        coach_skills: extendedUser.coach_skills.filter(fff => fff.id !== skill.id)
                      });
                    } else {
                      setExtendedUser({
                        ...extendedUser,
                        coach_skills: [...extendedUser.coach_skills, skill]
                      });
                    }
                  }}
                  variant="outlined"
                  icon={extendedUser.coach_skills.filter(fff => fff.id === skill.id).length > 0 ? <DoneIcon /> : <AddIcon />}
                  color={extendedUser.coach_skills.filter(fff => fff.id === skill.id).length > 0 ? "primary" : 'default'}
                />)}
              </Grid>}

              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!!mutationSave.isPending && <CircularProgress />}
                {!mutationSave.isPending && <Button
                  id="save_profile"
                  disabled={!extendedUser.first_name || !extendedUser.last_name}
                  contained
                  onClick={(e) => {
                    saveUser();
                  }}
                  label={t("client.general.buttons.submit", "Opslaan")}
                  sx={{
                    marginTop: 1
                  }}
                />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>}

    <Confirm
      open={confirmOpen}
      setOpen={setConfirmOpen}
      title={t("coach.submenu.detail.password_reset")}
      content={t("general.confirm.password_reset", "Bent u zeker dat u dit item wil verwijderen?")}
      execute={() => {
        mutationCustomAction.mutate({formData: {
          object: 'user',
          class_action: 'custom_api_forgot_pass',
          id: login?.id
        }});
      }}
    />

    <Confirm
      open={removeOpen}
      setOpen={setRemoveOpen}
      title={t("client.general.buttons.close_account")}
      content={t("general.confirm.close_account", "Bent u zeker dat u dit item wil verwijderen?")}
      execute={() => {
        mutationCustomAction.mutate({formData: {
          object: 'user',
          class_action: 'custom_api_close_acount',
          id: login?.id
        }});
      }}
    />

    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Grid>;
}

