import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import { PieChart, Cell, Pie, Legend, Tooltip, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar } from 'recharts';

import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';

import {
  Card,
  Dt,
  Page,
  Typography
} from '../../components/v2/styled';
import { PracticeLogoAvatar } from '../../components/v2/PracticeLogoAvatar';

import { NewsItem } from '../../models/NewsItem';
import { NutriMail } from '../../models/NutriMail';
import { Practice } from '../../models/Practice';
import { Workshop } from '../../models/Workshop';
import { User } from '../../models/User';

import HelpersPractice from '../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const CoachDashboard: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const is_nutri_workshop = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_workshop'});
  const is_nutri_mail = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_mail'});

  const agesStatsColours:string[] = ['#e0e0e0', '#b2d48c', '#a5d173', '#86b354', '#97BE6B', '#6d8a4d', '#577339', '#3d5424'];
  const genderColours:string[] = ['#e0e0e0', '#f5a9f2', '#aba9f5', '#8f991f'];
  const returningStatsColours:string[] = ['#bacca7', '#a9c787', '#97BE6B', '#6e9146'];

  const {
    data: agesStats,
    isLoading: agesStatsLoading,
    isError: agesStatsError,
    isSuccess: agesStatsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["ages_stats", login?.id, DateTime.now().toFormat("dd/LL/y")],
    queryFn: () =>
      fetch_one<{custom_result: {data: {name: string; value: number}[]}}>(
        `/nl/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_stats_ages',
          practice_id: currentPractice?.id || login?.practice_id,
        },
        login
      ),
    enabled: !!(currentPractice?.id || login?.practice_id),
  });

  const {
    data: genderStats,
    isLoading: genderStatsLoading,
    isError: genderStatsError,
    isSuccess: genderStatsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["gender_stats", login?.id, DateTime.now().toFormat("dd/LL/y")],
    queryFn: () =>
      fetch_one<{custom_result: {data: {name: string; value: number}[]}}>(
        `/nl/v3/objects/custom_action`,
        {
          object: 'user',
          class_action: 'custom_api_stats_gender',
          practice_id: currentPractice?.id || login?.practice_id,
        },
        login
      ),
    enabled: !!(currentPractice?.id || login?.practice_id),
  });

  const {
    data: returningStats,
    isLoading: returningStatsLoading,
    isError: returningStatsError,
    isSuccess: returningStatsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["returning_stats", login?.id, DateTime.now().toFormat("dd/LL/y")],
    queryFn: () =>
      fetch_one<{custom_result: {data: {name: string; value: number}[]}}>(
        `/nl/v3/objects/custom_action`,
        {
          object: 'appointment',
          class_action: 'custom_api_stats_returning',
          practice_id: currentPractice?.id || login?.practice_id,
        },
        login
      ),
    enabled: !!(currentPractice?.id || login?.practice_id),
  });

  const {
    data: newestUsers,
    isLoading: newestUsersLoading,
    isError: newestUsersError,
    isSuccess: newestUsersSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["newest_users", "coach"],
    queryFn: () =>
      fetch_all<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'full_name', 'created_at'],
          per_page: 5,
          page: 0,
          order: "created_at DESC",
          filter: {
            advanced: {
              practice_id: currentPractice?.id || login?.practice_id
            }
          }
        },
        login
      ),
    enabled: !!login && !!is_nutri_workshop,
  });

  const {
    data: newsItems,
    isLoading: newsItemsLoading,
    isError: newsItemsError,
    isSuccess: newsItemsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["news_items", "coach"],
    queryFn: () =>
      fetch_all<NewsItem>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'news_item',
          fields: ['id', 'published_at', 'publisher', 'title', 'content', 'teaser', 'get_item', 'practice_id'],
          per_page: 1,
          page: 0,
          order: "published_at DESC",
          filter: {
            advanced: {
              practice_id: currentPractice?.id || login?.practice_id,
              is_coach_only: 1
            }
          }
        },
        login
      ),
    enabled: !!login && !!is_nutri_workshop,
  });

  const {
    data: nutriMails,
    isLoading: nutriMailsLoading,
    isError: nutriMailsError,
    isSuccess: nutriMailsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["nutri_mails", "coach", login?.id],
    queryFn: () =>
      fetch_all<NutriMail>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'nutri_mail',
          fields: ['id', 'subject', 'read', 'created_at'],
          per_page: 5,
          page: 0,
          order: 'created_at DESC',
          filter: {
            advanced: {
              to_id: login?.id,
              deleted: 0,
              sent: 1
            }
          }
        },
        login
      ),
    enabled: !!login && !!is_nutri_mail,
  });

  const {
    data: workshops,
    isLoading: workshopsLoading,
    isError: workshopsError,
    isSuccess: workshopsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["workshops", "soon", currentPractice?.id],
    queryFn: () =>
      fetch_all<Workshop>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'workshop',
          fields: ['id', 'start_time', 'get_medium', 'subject', 'practice|id/get_medium/name', 'get_subscriber_ids', 'get_backup_ids', 'max_subscribers', 'max_backups'],
          per_page: 3,
          page: 0,
          order: 'start_time ASC',
          filter: {
            advanced: {
              practice_id: currentPractice?.id || login?.practice_id
            }
          }
        },
        login
      ),
    enabled: !!login,
  });








  const RADIAN = Math.PI / 180;
  // @ts-ignore
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {percent * 100 < 5 ? '' : `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  let term = t("coach.dashboard.workshops.title", "Workshops");
  if (!!currentPractice?.settings?.workshops_plural) {
    term = currentPractice.settings.workshops_plural;
  }

  // let weightInputDisabled = false;
  // if (lastWeight && lastWeight.date && DateTime.fromISO(lastWeight.date).toFormat("DDD") === DateTime.now().toFormat("DDD")) weightInputDisabled = true;

  return <Page title={`${t("coach.dashboard.hello", "Hallo")} ${login?.first_name},`}>
    <Grid container spacing={2}>

      <Card
        // title={login?.practice_name}
      >
        <PracticeLogoAvatar
          showName
          practice={{get_medium: login?.practice_get_medium, name: login?.practice_name, item_is_square: login?.practice_item_is_square} as Practice}
        />
      </Card>

      <Card
        loading={newsItemsLoading}
        actionNavFunc={() => {
          if (!!newsItems && !!newsItems[0]) navigate(`/news/${newsItems[0].id}`);
        }}
        actionName={t("admin.dashboard.news.action")}
        media={
          !!newsItems && !!newsItems[0] && newsItems[0].get_item ? [newsItems[0].get_item, newsItems[0].title] : undefined
        }
      >
        {!!newsItems && !!newsItems[0] && <>
          <Box sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            padding: '10px 40px',
            ...(newsItems[0].practice_id ? {
              backgroundColor: theme.palette.tertiary?.main
            } : {
              backgroundColor: '#97BE6B'
            })
          }}>{newsItems[0].publisher}</Box>
          <Typography variant="h5" sx={{
            marginBottom: 1
          }}>{newsItems[0].title}</Typography>
          <Typography html>{newsItems[0].teaser || (newsItems[0].content ? newsItems[0].content.substring(0, 100) : "")}</Typography>
        </>}
      </Card>

      {!!workshopsSuccess && !!is_nutri_workshop && workshops.length > 0 && <Card
        title={term}
        loading={workshopsLoading}
        actionNav="/workshops"
        actionName={t("coach.dashboard.workshops.action")}
        larger
      >
        <List>
          {workshops.map(workshop => (<ListItem>
            <ListItemAvatar>
              {workshop.get_medium && <Avatar
                src={workshop.get_medium}
                title={workshop.subject}
              />}
              {!workshop.get_medium && <Avatar
                src={workshop.practice_get_medium}
                title={workshop.practice_name}
              />}
            </ListItemAvatar>
            <ListItemText
              primary={workshop.subject}
              secondary={<><Dt d={workshop.start_time} f="cccc DDD - t" /><br />{[(workshop.get_subscriber_ids || []).length, "/", workshop.max_subscribers].join("")} + {[(workshop.get_backup_ids || []).length, "/", workshop.max_backups].join("")}</>}
            />
          </ListItem>))}
        </List>
      </Card>}

      {!!nutriMailsSuccess && !!is_nutri_mail && nutriMails.length > 0 && <Card
        title={t("coach.dashboard.nutri_mails.title", "Nieuwste NutriMails")}
        loading={nutriMailsLoading}
        actionNav="/inbox"
        actionName={t("coach.dashboard.nutri_mails.action")}
        larger
      >
        <Grid container sx={{
          marginTop: 1
        }} spacing={2}>
          {!!nutriMailsSuccess && nutriMails.map(m => <>
            <Grid item xs={6} style={{fontWeight: (m.read ? 'normal' : 'bold')}}>
              {m.subject}
            </Grid>
            <Grid item xs={6} style={{fontWeight: (m.read ? 'normal' : 'bold')}}>
              {DateTime.fromISO(m.created_at).toFormat("DDD t")}
            </Grid>
          </>)}
        </Grid>
      </Card>}

      {!!newestUsersSuccess && !!is_nutri_mail && newestUsers.length > 0 && <Card
        title={t("coach.dashboard.user_creations.title", "Nieuwste clienten")}
        loading={newestUsersLoading}
        actionNav="/users"
        actionName={t("coach.dashboard.user_creations.action", "Clienten")}
        larger
      >
        <Grid container sx={{
          marginTop: 1
        }} spacing={2}>
          {!!newestUsersSuccess && newestUsers.map(u => <>
            <Grid item xs={6}>
              {u.full_name}
            </Grid>
            <Grid item xs={6}>
              {DateTime.fromISO(u.created_at).toFormat("DDD t")}
            </Grid>
          </>)}
        </Grid>
      </Card>}

      <Card
        title={t("coach.dashboard.client_appointment.appointments_title", "Client afspraken")}
        loading={returningStatsLoading}
        larger
      >
        <Grid container sx={{
          marginTop: 1
        }} spacing={2}>
          <Grid item xs={12}>
            {!!returningStatsSuccess && <Box sx={{width: '100%', height: '300px'}}>
              <ResponsiveContainer>
                <PieChart width={400} height={300}>
                  <Pie
                    data={returningStats.custom_result.data}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    outerRadius={90}
                    fill="#8884d8"
                    labelLine={false}
                    label={renderCustomizedLabel}
                  >
                    {returningStats.custom_result.data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={returningStatsColours[index % returningStatsColours.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>}
          </Grid>
        </Grid>
      </Card>

      <Card
        title={t("coach.dashboard.client_appointment.ages_title", "Client leeftijden")}
        loading={agesStatsLoading}
        larger
      >
        <Grid container sx={{
          marginTop: 1
        }} spacing={2}>
          <Grid item xs={12}>
            {!!agesStatsSuccess && <Box sx={{width: '100%', height: '300px'}}>
              <ResponsiveContainer>
                <BarChart width={400} height={300} data={agesStats.custom_result.data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#8884d8">
                    {agesStats.custom_result.data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={agesStatsColours[index % agesStatsColours.length]} />
                    ))}
                  </Bar>
                  <Legend />
                </BarChart>
              </ResponsiveContainer>
            </Box>}
          </Grid>
        </Grid>
      </Card>

      <Card
        title={t("coach.dashboard.client_appointment.gender_title", "Client verdeling")}
        loading={genderStatsLoading}
        larger
      >
        <Grid container sx={{
          marginTop: 1
        }} spacing={2}>
          <Grid item xs={12}>
            {!!genderStatsSuccess && <Box sx={{width: '100%', height: '300px'}}>
              <ResponsiveContainer>
                <PieChart width={400} height={300}>
                  <Pie
                    data={genderStats.custom_result.data}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                    outerRadius={90}
                    fill="#8884d8"
                    labelLine={false}
                    label={renderCustomizedLabel}
                  >
                    {genderStats.custom_result.data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={genderColours[index % genderColours.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>}
          </Grid>
        </Grid>
      </Card>

    </Grid>
  </Page>;
}
