import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useMutation, useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import {
  Alert,
  Portal,
  Snackbar,
} from '@mui/material';

// import {
//   Typography
// } from '../../../components/v2/styled';

// const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void
}

export const SnackDestroyed: FunctionComponent<Props> = ({open, setOpen}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  
  return <Portal>
    <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} sx={{zIndex: 99999}} onClose={() => {
      setOpen(false);
    }}>
      <Alert
        onClose={() => {
          setOpen(false);
        }}
        severity="info"
        variant="filled"
        sx={{
          width: '100%',
          zIndex: 1401
        }}
      >
        {t("general.snack.destroyed")}
      </Alert>
    </Snackbar>
  </Portal>;
}
