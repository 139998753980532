import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  Page,
  Select,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { MergeRequest } from '../../../models/MergeRequest';
import { User } from '../../../models/User';

const { DateTime } = require("luxon");

type Props = {}

export const CoachUserMerge: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  // const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [clientSearch, setClientSearch] = useState<string>('');
  const [newMerge, setNewMerge] = useState<MergeRequest>({});
  const [savedOpen, setSavedOpen] = useState<boolean>(false);

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["user", 'short_email', (currentPractice?.id || login?.practice_id), objectId],
    queryFn: () =>
      fetch_one<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'user',
          id: objectId,
          fields: [
            'id', 'first_name', 'last_name', 'email'
          ],
          practice_id: (currentPractice?.id || login?.practice_id)
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
      };
    },
    enabled: !!(currentPractice?.id || login?.practice_id) && parseInt(objectId || '', 10) > 0,
  });

  const {
    data: clients,
    isLoading: clientsLoading,
    isError: clientsError,
    isSuccess: clientsSuccess,
    // refetch: clientsRefetch,
  } = useQuery({
    queryKey: ["clients", currentPractice?.id, clientSearch],
    queryFn: () =>
      fetch_all<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'full_name', 'last_login_date', 'language_id'],
          order: "first_name ASC, last_name ASC",
          filter: {
            search: clientSearch,
            advanced: {
              for_practice: (currentPractice?.id || login?.practice_id),
              enabled: '1'
            }
          }
        },
        login
      ),
    select: (d) => {
      return d.filter(fff => fff.id?.toString() !== objectId);
    },
    enabled: !!currentPractice?.id
  });






  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<MergeRequest>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      navigate("/merge_requests");
    },
  });









  useEffect(() => {
    if (!!object) setNewMerge({
      ...newMerge,
      original_id: object.id
    });
  }, [object]);
  
  return <Page
    title={object?.first_name}
    titleCrumbs={[
      {
        title: t("coach.menu.users", "Cliënten"),
        path: "/users"
      },
      {
        title: !!object?.first_name ? [object?.first_name, object?.last_name].join(" ") : 'Cliënt',
        path: `/user/${object?.id}/detail`
      }
    ]}
  >
    <Paper sx={{
      padding: 2
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.merge_requests.original_id", "Huidig profiel")}</Typography>
          <Typography>{object?.first_name} {object?.last_name} ({object?.email})</Typography>
        </Grid>

        <Grid item xs={12} sx={{marginTop: 3}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.merge_requests.placeholders.copy_id", "Zoek hier de andere account")}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            id="client_search"
            caption={t("coach.models.appointments.client_search", "Zoek client")}
            value={clientSearch}
            onChange={(e) => {
              setClientSearch(e);
            }}
          />
          <Typography sx={{fontSize: '0.7rem', fontStyle: 'italic', marginBottom: 1}}>{(clients || []).length} {t("general.results_found")}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            fullWidth
            caption={t("coach.models.appointments.client", "Client")}
            starred
            displayEmpty
            id="user_id"
            value={newMerge.copy_id || ''}
            setValue={(v) => {
              setNewMerge({
                ...newMerge,
                copy_id: !!v ? parseInt(v.toString(), 10) : undefined
              });
            }}
          >
            <MenuItem value="">------</MenuItem>
            {(clients || []).map(client => <MenuItem value={client.id}>{client.full_name}</MenuItem>)}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <TextInput
            id="comment"
            multiline
            caption={t("coach.models.merge_requests.comment", "Extra opmerking")}
            value={newMerge.comment || ''}
            onChange={(e) => {
              setNewMerge({
                ...newMerge,
                comment: e
              });
            }}
          />
        </Grid>
        
        



        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: 'sticky',
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={!newMerge.copy_id || !newMerge.original_id}
            contained
            onClick={(v) => {
              mutationSave.mutate({
                formData: {
                  object: 'merge_request',
                  fields: ['id'],
                  ob: {
                    ...newMerge,
                    practice_id: currentPractice?.id || login?.practice_id,
                    coach_id: login?.id,
                    current_status: 0
                  }
                }
              });
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              navigate("/merge_requests");
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>

      </Grid>
    </Paper>
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Page>;
}
