import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  CardContent,
  CardMedia,
  Chip,
  Divider
} from '@mui/material';

import {
  Typography
} from '../../../components/v2/styled';
import { CardBase } from './Base';

import { User } from '../../../models/User';

type Props = {
  coach: User
};

export const CardCoach: FunctionComponent<Props> = ({coach}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  return (<CardBase>
    <CardMedia
      sx={{
        height: 100,
        backgroundPositionY: '40%',
        backgroundColor: (!!(coach.settings || {}).is_background_colour ? (!!(coach.settings || {}).background_colour ? (coach.settings || {}).background_colour : 'rgba(0, 0, 0, 0.2)') : 'transparent')
      }}
      image={!(coach.settings || {}).is_background_colour ? coach.get_bg_medium : undefined}
      title={`${coach.first_name} ${coach.last_name}`}
    />
    <CardContent style={{marginTop: '-100px', marginBottom: 'auto'}}>
      <Avatar sx={{width: 100, height: 100}} src={coach.get_medium} />

      <Typography sx={{color: theme.palette.tertiary?.main, marginTop: 1}}>{coach.first_name}</Typography>
      <Typography sx={{color: theme.palette.tertiary?.main, fontSize: '0.75rem'}} variant="body2">{coach.last_name}</Typography>

      {(coach.coach_skills || []).length > 0 && <>
        <Divider sx={{marginTop: 1, marginBottom: 1}} />

        <Typography sx={{color: theme.palette.primary.main, fontStyle: 'italic', fontSize: '0.75rem'}}>{t("client.models.coaches.skills", "Skills")}</Typography>
        {(coach.coach_skills || []).map(skill => <Chip
          color="primary"
          size="small"
          variant="outlined"
          label={skill.name}
          sx={{
            marginRight: 0.5,
            marginBottom: 0.5,
            marginTop: 0.5
          }}
        />)}
      </>}

      {!!coach.profile_text && <>
        <Divider sx={{marginTop: 1, marginBottom: 1}} />
        <Typography html>{coach.profile_text}</Typography>
      </>}
    </CardContent>
  </CardBase>);
}