import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  Button as Btn,
  DialogActions,
  Grid,
  MenuItem,
} from '@mui/material';

import {
  Select,
  TextInput
} from '../../../components/v2/styled';
import { Base } from './Base';
import { Confirm } from './Confirm';

import { DayNote } from '../../../models/DayNote';
import { User } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  onClick: () => void;
  onDestroy: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
  dayNote: DayNote;
  setDayNote: (d:DayNote) => void;
};

export const CoachDayNote: FunctionComponent<Props> = ({open, setOpen, onClick, onDestroy, dayNote, setDayNote}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });








  return (<>
    <Base
      name="day_note_edit"
      title={t("coach.submenu.agenda.day_note")}
      fullWidth
      maxWidth="lg"
      content={<>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextInput
              id="date"
              disabled
              value={dayNote.date || ''}
              caption={t("coach.submenu.agenda.day_note_date")}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              fullWidth
              caption={t("coach.submenu.agenda.day_note_coach", "Coach")}
              displayEmpty
              id="day_note_coach"
              value={dayNote.user_id || ''}
              setValue={(v) => {
                setDayNote({
                  ...dayNote,
                  user_id: v.toString() === '' ? undefined : parseInt(v.toString(), 10)
                });
              }}
            >
              <MenuItem value="">------</MenuItem>
              {(coaches || []).map(coach => <MenuItem key={coach.id} value={coach.id}>{coach.full_name}</MenuItem>)}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="note"
              multiline
              value={dayNote.note || ''}
              caption={t("coach.submenu.agenda.day_note_note", "Nota")}
              onChange={(v) => {
                setDayNote({
                  ...dayNote,
                  note: v
                });
              }}
            />
          </Grid>
        </Grid>

        <Confirm
          open={confirmOpen}
          setOpen={setConfirmOpen}
          title={t("general.confirm.delete_object_title")}
          content={t("general.confirm.delete_object")}
          execute={() => {
            onDestroy();
          }}
        />
      </>}
      actions={<DialogActions>
        {!!dayNote.id && <Btn
          onClick={(e) => {
            setConfirmOpen(true);
          }}
          color="secondary"
        >{t("coach.general.actions.destroy")}</Btn>}
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.close")}</Btn>
        <Btn
          onClick={onClick}
          color="primary"
        >{t("coach.general.actions.save")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}
